import React, { useState, useRef } from 'react'
import Layout from './Layout';
import {
  Button,
  Row,
  Col,
  Card,
  Form,
  FormControl,
  CardBody,
  Table,
  Spinner,
} from "react-bootstrap";
import config from "config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import indiancitizen from "../assets/images/indiancitizen.webp";
import sociallyaware from "../assets/images/sociallyaware.webp";
import uniquebusiness from "../assets/images/uniquebusiness.webp";
import lowinvestment from "../assets/images/lowinvestment.webp";
import peoplecometous from "../assets/images/peoplecometous.webp";
import essentialcomodity from "../assets/images/essentialcomodity.webp";
import santoshshedge from "../assets/images/santoshshedge.webp";
import partnerimage from "../assets/images/partnerimage.webp";
import vector from "../assets/images/vector.png";
import image from "../assets/images/image.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiamond } from "@fortawesome/free-solid-svg-icons";
import bannerlight from "@/assets/images/bannerlight.webp";
import woman from "../assets/images/woman.webp";
import { useNavigate } from "react-router-dom";
import businesspartner from "@/assets/images/businesspartner.webp";
import lastpanel from "@/assets/images/lastpanel.webp";
import { statedropdown } from "../helpers";
const options = [
  { value: "Franchise Partner", label: "Franchise Partner" },
  { value: "District Franchise Partner", label: "District Franchise Partner" },
  { value: "Regional Franchise Partner", label: "Regional Franchise Partner" },
  { value: "Zonal Franchise Partner", label: "Zonal Franchise Partner" },
  { value: "State Franchise Partner", label: "State Franchise Partner" },
];

export default function LandingBecomeBusinessPartner() {
  const initialFormData = {
    name: "",
    contact: "",
    email: "",
    city: "",
    businesstype: "",
    subject: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isDisabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState({});

  const sub = "Franchise Enquiry";

  const navigate = useNavigate();
  const formRef = useRef(null);

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth", // for smooth scrolling
  //   });
  // };

  // Function to handle input change and clear error when value exists
  const handleChange = (e) => {
    const { id, value } = e.target;
    // console.warn(" id, value->>>>>>>", { id, value });
    setFormData({ ...formData, [id]: value });

    // Clear error if the field is valid
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: null, // Clear error for this field
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "This field is required", // Mark as required if field is cleared
      }));
    }
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formData.name) formErrors.name = "Name is required";

    if (!formData.contact) {
      formErrors.contact = "Contact number is required";
    } else if (!/^\d+$/.test(formData.contact)) {
      formErrors.contact = "Contact number must be numeric";
    } else if (formData.contact.length !== 10) {
      formErrors.contact = "Contact number must be 10 digits";
    }

    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email address is invalid";
    }

    if (!formData.city) formErrors.city = "City is required";

    return formErrors;
  };

  const handleSubmi1t = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    console.warn("e.target.elements->>>>", formData);

    const { name, contact, email, city, businesstype, subject } = formData;
    let details = {
      name: name.value,
      mobileno: contact.value,
      email: email.value,
      city: city.value,
      businesstype: businesstype.value.value,
      subject: subject.value,
    };
    console.warn("details->>>>>>>>", details);

    debugger;
    if (Object.keys(formErrors).length === 0) {
      console.log("Form Data:", formData);
      // Clear the form
      setFormData(initialFormData);
      alert("Form submitted successfully!");

      try {
        let response = await fetch(`${config.emailServerUrl}/fr-enquiry`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Corrected header
          },
          body: JSON.stringify(details),
        });

        let result = await response.json();
        console.log("result.status", result.status);
        // formRef.resetForm();
        setFormData({
          name: "",
          contact: "",
          email: "",
          city: "",
          businesstype: "",
          subject: "",
        });
        formRef.current.reset();

        toast.success("Form Submitted Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error("Form Submission Failed", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      setErrors(formErrors);
    }
  };

  const validateField = (name, value) => {
    let error;
    switch (name) {
      case "name":
        if (!value) {
          error = "Name is required";
        } else if (!/^[a-zA-Z ]+$/.test(value)) {
          error = "Name can only contain letters and spaces";
        }
        break;
      case "contact":
        if (!value) {
          error = "Mobile number is required";
        } else if (!/^[0-9]{10}$/.test(value)) {
          error = "Mobile number must be 10 digits";
        }
        break;
      case "email":
        if (!value) {
          error = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = "Email is invalid";
        }
        break;
      case "city":
        if (!value) {
          error = "City is required";
        } else if (!/^[a-zA-Z ]+$/.test(value)) {
          error = "City can only contain letters and spaces";
        }
        break;
      case "businesstype":
        if (!value || value === "") {
          error = "Business Type is required";
        } else {
          error = ""; // Clear the error when a value is selected
        }
        break;

      default:
        break;
    }
    return error;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, contact, email, city, businesstype } = formData;
    let details = {
      name: name,
      mobileno: contact,
      email: email,
      city: city,
      businesstype: businesstype.value,
      subject: "Business Partner Enquiry",
    };
    // Check for errors before submitting
    const validationErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) {
        validationErrors[key] = error;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setDisabled(true);
    try {
      let response = await fetch(`${config.emailServerUrl}/fr-enquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Corrected header
        },
        body: JSON.stringify(details),
      });

      let result = await response.json();
      console.log("result.status", result);

      if (result.message === "success") {
        setFormData({
          name: "",
          contact: "",
          email: "",
          city: "",
          businesstype: "",
          subject: "",
        });
        formRef.current.reset();
        navigate("/thankyou");
      } else {
        navigate("/errorEmail");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Form Submission Failed", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setDisabled(false);
    }
  };

  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);

  return (
    <Layout>
      <div
        className={`${
          isSafari === true ? "testimonial-safari" : "testimonial-fra"
        }`}
      >
        <div className="home-style">
          <div
            className="safari"
            style={{
              backgroundImage: `url(${bannerlight})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <ToastContainer />
            <Row className="pt-5 ">
              <Col lg="5" md="5" sm="12" xs="12" className="pe-0">
                <Row className="">
                  <Col lg="2" md="2" sm="12" xs="12"></Col>
                  <Col lg="10" md="10" sm="12" xs="12" className="">
                    <p className="headtext">
                      <span style={{ color: "#2b4bb6" }}>
                        Genivis Remedies-India's No.1
                      </span>{" "}
                      Fastest Growing Generic Medicine Manufacturing and
                      Distribution Company
                    </p>
                    <p className="tagline">
                      Start Your Own Business,Maximize Your Profits!
                    </p>
                    <Row className="justify-content-center">
                      <Col className="mb-3">
                        <div
                          className="badgestyle"
                          style={{ width: "fit-content" }}
                        >
                          <FontAwesomeIcon icon={faDiamond} className="me-2" />
                          Low Investment
                          <span>
                            <b> High Profit</b>
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3">
                        <div
                          className="badgestylegreen"
                          style={{ width: "fit-content" }}
                        >
                          <FontAwesomeIcon icon={faDiamond} className="me-2" />
                          Start Your
                          <span>
                            <b> Own Business</b>
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="">
                        <div
                          className="badgestyle"
                          style={{ width: "fit-content" }}
                        >
                          <FontAwesomeIcon icon={faDiamond} className="me-2" />
                          <span>
                            <b>2000+</b>
                          </span>{" "}
                          Successful Franchise Stores
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col
                lg="3"
                md="3"
                sm="12"
                xs="12"
                className="justify-content-center"
              >
                <img
                  src={santoshshedge}
                  className="bnlandingimg"
                  loading="lazy"
                  alt="Landing screen"
                />
              </Col>
              <Col lg="4" md="4" sm="12" xs="12">
                <Row className="justify-content-center">
                  <Col lg="8" className="px-lg-0 px-md-0">
                    <div className="styleform">
                      <Form
                        noValidate
                        onSubmit={handleSubmit}
                        className="about-style mt-0"
                        ref={formRef}
                      >
                        <div>
                          <p
                            className="subhead text-start mb-2"
                            style={{ color: "#2b4bb6" }}
                          >
                            Business Partner Enquiry
                          </p>
                        </div>
                        <Form.Group className="mb-2">
                          {/* <Form.Label className='lblstyle'>Name</Form.Label> */}
                          <Form.Control
                            type="text"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleChange}
                            name="name"
                            id="name"
                            isInvalid={!!errors.name}
                            autoComplete="off"
                            className="fieldstyle"
                            onKeyPress={(e) => {
                              var regex = new RegExp("^[a-zA-Z ]+$"); // Updated regex to allow spaces
                              var str = String.fromCharCode(
                                !e.charCode ? e.which : e.charCode
                              );
                              if (regex.test(str)) {
                                return true;
                              }
                              e.preventDefault();
                              return false;
                            }}
                          />
                          {errors.name && (
                            <FormControl.Feedback type="invalid">
                              {errors.name}
                            </FormControl.Feedback>
                          )}
                        </Form.Group>

                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          className="fieldstyle d-none"
                          name="subject"
                          id="subject"
                          value={sub}
                          autoComplete="off"
                          onChange={handleChange}
                        />

                        <Form.Group className="mb-2">
                          {/* <Form.Label className='lblstyle'>Contact No.</Form.Label> */}
                          <Form.Control
                            type="text"
                            placeholder="Number"
                            value={formData.contact}
                            onChange={handleChange}
                            className="fieldstyle"
                            autoComplete="off"
                            onKeyPress={(e) => {
                              var regex = new RegExp("^[0-9]+$");
                              var str = String.fromCharCode(
                                !e.charCode ? e.which : e.charCode
                              );

                              if (regex.test(str)) {
                                return true;
                              }
                              e.preventDefault();
                              return false;
                            }}
                            name="contact"
                            id="contact"
                            maxLength={10}
                            isInvalid={!!errors.contact}
                          />
                          {errors.contact && (
                            <Form.Control.Feedback type="invalid">
                              {errors.contact}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-2">
                          {/* <Form.Label className='lblstyle'>Email address</Form.Label> */}
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            name="email"
                            id="email"
                            isInvalid={!!errors.email}
                            className="fieldstyle"
                            autoComplete="off"
                          />
                          {errors.email && (
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-2">
                          {/* <Form.Label className='lblstyle'>City</Form.Label> */}
                          <Form.Control
                            type="text"
                            placeholder="City"
                            value={formData.city}
                            onChange={handleChange}
                            name="city"
                            id="city"
                            isInvalid={!!errors.city}
                            className="fieldstyle"
                            autoComplete="off"
                          />
                          {errors.city && (
                            <Form.Control.Feedback type="invalid">
                              {errors.city}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-2">
                          <Select
                            className={`${
                              !formData.businesstype && errors.businesstype
                                ? "errorBox"
                                : ""
                            } selectTo`}
                            value={formData.businesstype}
                            onChange={(selectedOption) =>
                              setFormData({
                                ...formData,
                                businesstype: selectedOption,
                              })
                            }
                            isClearable={true}
                            options={options}
                            menuPortalTarget={document.body}
                            placeholder="Business Type"
                            name="businesstype"
                            id="businesstype"
                            isInvalid={!!errors.businesstype}
                            styles={statedropdown}
                          />
                          {!formData.businesstype && errors.businesstype && (
                            <span
                              className="text-danger"
                              style={{
                                fontSize: ".875em",
                              }}
                            >
                              {errors.businesstype}
                            </span>
                          )}
                        </Form.Group>
                        <div className="aboutus">
                          <Button
                            className="w-100 franchisebtn"
                            onClick={handleSubmit}
                            disabled={isDisabled}
                          >
                            {isDisabled ? (
                              <>
                                <Spinner className="spinner_style" /> Submitting
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <div className="aboutus">
          <div className="bgcolor mt-0">
            <div className="text-end">
              {/* <img src={topsticky} className='topsticky' onClick={scrollToTop} /> */}
            </div>
            <Row className="justify-content-center">
              <Col lg="9" className="">
                <Row className="my-5">
                  <Col lg="6" md="6" sm="12" xs="12" className="">
                    <div className="home-style mt-0">
                      <p
                        className="headtext mx-0"
                        style={{
                          color: "white",
                          textAlign: "start",
                          animation: "none",
                        }}
                      >
                        Don’t Just Dream of Success – Make It Happen with{" "}
                        <span style={{ fontWeight: "500" }}>
                          Genivis Remedies!
                        </span>
                      </p>
                    </div>
                    <p className="aboutsub">
                      <span style={{ textDecoration: "underline" }}>
                        Lead a Growing Network
                      </span>{" "}
                      - Become Our State, District, Zonal or Regional Head and
                      Drive Real Change with Genivis Remedies!
                    </p>
                    <p className="textstyle">
                      With the demand for affordable generic medicines on the
                      rise, now is the perfect time to{" "}
                      <span style={{ fontWeight: "500" }}>
                        Join Genivis Remedies as a leader in healthcare.
                      </span>
                    </p>
                    <p className="textstyle">
                      A{" "}
                      <span style={{ fontWeight: "500" }}>
                        Genivis Remedies Business Partner
                      </span>{" "}
                      is a key marketing individual or company responsible for
                      driving the penetration of our Franchise model within an
                      assigned State, District, Zone, or Region. The Business
                      Partner will oversee all franchise activities in their
                      designated area, as determined by{" "}
                      <span style={{ fontWeight: "500" }}>
                        Genivis Remedies (I) Pvt. Ltd.
                      </span>
                    </p>
                    {/* <Button type="button" className='franchisebtn'
                                        // onClick={(e) => {
                                        //     e.preventDefault();
                                        //     navigate("/aboutus");
                                        // }}
                                        >Know More</Button> */}
                  </Col>
                  <Col lg="6" md="6" sm="12" xs="12" className="my-auto">
                    <img
                      className="w-100 "
                      src={image}
                      alt="Second slide"
                      loading="lazy"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <div className="earnings">
          <Row className="justify-content-center my-4">
            <Col lg="9">
              <p className="head" style={{ textAlign: "start" }}>
                <span style={{ color: "black" }}>Earnings</span> Potentials
              </p>
              <Row className="">
                <Col lg="4" md="4" sm="12" xs="12" className="mb-2">
                  <Card className="mb-2">
                    <CardBody>
                      <p className="mb-0 d-flex earningtxt">
                        <div
                          style={{
                            background: "#2B4BB6",
                            height: "4px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                          className="me-2"
                        >
                          <span style={{ visibility: "hidden" }}>..</span>
                        </div>
                        One Time Investment
                      </p>
                    </CardBody>
                  </Card>
                  <Card className="mb-2">
                    <CardBody>
                      <p className="mb-0 d-flex earningtxt">
                        <div
                          style={{
                            background: "#2B4BB6",
                            height: "4px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                          className="me-2"
                        >
                          <span style={{ visibility: "hidden" }}>..</span>
                        </div>
                        LifeTime Royalty Benefits (2% Per Month)
                      </p>
                    </CardBody>
                  </Card>
                  <Card className="mb-2">
                    <CardBody>
                      <p className="mb-0 d-flex earningtxt">
                        <div
                          style={{
                            background: "#2B4BB6",
                            height: "4px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                          className="me-2"
                        >
                          <span style={{ visibility: "hidden" }}>..</span>
                        </div>
                        Recruitment Incentive (10k Per Franchise)
                      </p>
                    </CardBody>
                  </Card>
                  <Card className="mb-2">
                    <CardBody>
                      <p className="mb-0 d-flex earningtxt">
                        <div
                          style={{
                            background: "#2B4BB6",
                            height: "4px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                          className="me-2"
                        >
                          <span style={{ visibility: "hidden" }}>..</span>
                        </div>
                        Domestic and International Tours
                      </p>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <p className="mb-0 d-flex earningtxt">
                        <div
                          style={{
                            background: "#2B4BB6",
                            height: "4px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                          className="me-2"
                        >
                          <span style={{ visibility: "hidden" }}>..</span>
                        </div>
                        Half yearly and Yearly contest
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="8" md="8" sm="12" xs="12" className="mb-2">
                  <Card className="earningcard h-100">
                    <CardBody className="my-auto">
                      <p className="normaltxt">For Example-</p>
                      <p className="my-auto normaltxt">
                        If a Zonal Head Recruit{" "}
                        <span style={{ fontWeight: "700" }}>
                          200 franchises{" "}
                        </span>
                        & if the{" "}
                        <span style={{ fontWeight: "700" }}>
                          Purchase is 5 lakhs Per Month
                        </span>
                        then the ZH can{" "}
                        <span style={{ fontWeight: "700" }}>
                          earn 20 Lakhs Per Month{" "}
                        </span>
                        Sales Incentives/Lifetime Monthly Royalty.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="tblstyle">
          <Row className="justify-content-center my-4">
            <Col lg="9">
              <div style={{ boxShadow: "0 0 10px #ccc" }}>
                <Table striped responsive className="tablestyle mb-0">
                  <thead>
                    <tr>
                      <th>Profit Growth & Incentive Plan</th>
                      <th>State Head</th>
                      <th>Zonal Head</th>
                      <th>Regional Head</th>
                      <th>District Head</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Investment(in INR)</td>
                      <td>75L To 1 Cr.</td>
                      <td>50 Lakhs</td>
                      <td>15 Lakhs</td>
                      <td>5 Lakhs</td>
                    </tr>
                    <tr>
                      <td>Recruitment Incentive</td>
                      <td>10%</td>
                      <td>10%</td>
                      <td>15%</td>
                      <td>40%</td>
                    </tr>
                    <tr>
                      <td>Sales Incentive(in INR)</td>
                      <td>2%</td>
                      <td>2%</td>
                      <td>3%</td>
                      <td>5%</td>
                    </tr>
                    <tr>
                      <td>Earning Potentials(Per Month)</td>
                      <td>10-15 Lakhs</td>
                      <td>5-10 Lakhs</td>
                      <td>3-6 Lakhs</td>
                      <td>2-3 Lakhs</td>
                    </tr>
                    <tr>
                      <td colSpan={5} className="text-center">
                        <b>
                          Earnings may vary based on state-specific factors and
                          business dynamics.
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
        <div className="ourmilestone mt-5">
          <div className="slider-container">
            <Row className="justify-content-center mb-4">
              <Col className="justify-content-center" lg="9">
                <p className="head">
                  <span style={{ color: "black" }}>Why Choose</span> Genivis
                  Remedies Franchise?
                </p>
                <Row>
                  <Col>
                    <Card className="mb-3 whyuscard">
                      <Card.Body className="text-center content">
                        <img
                          variant="top"
                          src={lowinvestment}
                          className="whyusimg mb-3"
                          alt="lowinvestment"
                          loading="lazy"
                        />
                        {/* <Card.Title className='ffamily'>29th May 2024</Card.Title> */}
                        <Card.Subtitle className="mb-2 text-muted subtitlehead">
                          Lower Investment. Higher Returns.
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mb-3 whyuscard">
                      <Card.Body className="text-center content">
                        <img
                          variant="top"
                          src={uniquebusiness}
                          className="whyusimg mb-3"
                          alt="uniquebusiness"
                          loading="lazy"
                        />

                        <Card.Subtitle className="mb-2 text-muted subtitlehead">
                          Unique Business
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mb-3 whyuscard">
                      <Card.Body className="text-center content">
                        <img
                          variant="top"
                          src={sociallyaware}
                          className="whyusimg mb-3"
                          alt="sociallyaware"
                          loading="lazy"
                        />
                        <Card.Subtitle className="mb-2 text-muted subtitlehead">
                          Socially aware business
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="mb-3 whyuscard">
                      <Card.Body className="text-center content">
                        <img
                          variant="top"
                          src={peoplecometous}
                          className="whyusimg mb-3"
                          alt="peoplecometous"
                          loading="lazy"
                        />
                        <Card.Subtitle className="mb-2 text-muted subtitlehead">
                          People should come to us
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mb-3 whyuscard">
                      <Card.Body className="text-center content">
                        <img
                          variant="top"
                          src={indiancitizen}
                          className="whyusimg mb-3"
                          alt="indiancitizen"
                          loading="lazy"
                        />
                        <Card.Subtitle className="mb-2 text-muted subtitlehead">
                          Need of Indian Citizens
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mb-3 whyuscard">
                      <Card.Body className="text-center content">
                        <img
                          variant="top"
                          src={essentialcomodity}
                          className="whyusimg mb-3"
                          alt="essentialcomodity"
                          loading="lazy"
                        />
                        <Card.Subtitle className="mb-2 text-muted subtitlehead">
                          Essential commodity with lifelong business potential
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>

        <div className="benifits mt-0">
          <Row className="justify-content-center">
            <Col lg="9" md="11" sm="12" xs="12">
              <p className="head">
                <span style={{ color: "black" }}>GENIVIS </span> USP’S
              </p>
              <div
                className="support"
                style={{
                  backgroundImage: `url(${businesspartner})`,
                }}
              >
                <Row className="justify-content-center h-100">
                  <Col lg="6" md="6" sm="12" xs="12" className="my-auto">
                    {/* <h1 className='text-white benifithead mb-4'>Franchise Support </h1> */}
                    <div className="d-flex">
                      <img
                        src={vector}
                        className="tick me-2"
                        alt="vector"
                        loading="lazy"
                      />
                      <p className="liststyle">
                        5000+ WHO-GMP-certified Products
                      </p>
                    </div>
                    <div className="d-flex">
                      <img
                        src={vector}
                        className="tick me-2"
                        alt="vector"
                        loading="lazy"
                      />
                      <p className="liststyle">600 Monopoly products</p>
                    </div>
                    <div className="d-flex">
                      <img
                        src={vector}
                        className="tick me-2"
                        alt="vector"
                        loading="lazy"
                      />
                      <p className="liststyle">
                        2000+ Successfully Running Outlets
                      </p>
                    </div>
                    <div className="d-flex">
                      <img
                        src={vector}
                        className="tick me-2"
                        alt="vector"
                        loading="lazy"
                      />
                      <p className="liststyle">
                        Mobile Application, E-Commerce support & High-end
                        Software(ERP)
                      </p>
                    </div>
                    <div className="d-flex">
                      <img
                        src={vector}
                        className="tick me-2"
                        alt="vector"
                        loading="lazy"
                      />
                      <p className="liststyle">
                        T.V., Radio and Social Media Advertising support
                      </p>
                    </div>
                    <div className="d-flex">
                      <img
                        src={vector}
                        className="tick me-2"
                        alt="vector"
                        loading="lazy"
                      />
                      <p className="liststyle">Training Programs</p>
                    </div>
                  </Col>
                  <Col
                    lg="6"
                    md="6"
                    sm="12"
                    xs="12"
                    className="text-center my-auto"
                  >
                    <div className="img2">
                      <img
                        src={partnerimage}
                        className="businessimg"
                        alt="partnerimage"
                        loading="lazy"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="lastsection mt-5"
          style={{
            backgroundImage: `url(${lastpanel})`,
          }}
        >
          <Row className="justify-content-center">
            <Col lg="9">
              <Row className="h-100">
                <Col lg="4" md="4" sm="12" xs="12" className="text-center">
                  <img
                    src={woman}
                    className="womanimg"
                    alt="woman"
                    loading="lazy"
                  />
                </Col>
                <Col
                  lg="8"
                  md="8"
                  sm="12"
                  xs="12"
                  className="text-center my-auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                    className="mb-3"
                  >
                    <g clip-path="url(#clip0_417_4638)">
                      <path
                        d="M2.33575 51.3299C2.02777 51.3286 1.72261 51.3884 1.43784 51.5057C1.15308 51.623 0.894352 51.7955 0.676582 52.0133C0.458811 52.231 0.286306 52.4898 0.169013 52.7745C0.0517209 53.0593 -0.00803815 53.3644 -0.00681761 53.6724C-0.00576173 53.9789 0.0556769 54.2822 0.173988 54.565C0.292299 54.8478 0.465164 55.1045 0.682707 55.3204C0.90025 55.5363 1.15821 55.7073 1.44184 55.8235C1.72548 55.9397 2.02923 55.9989 2.33575 55.9977H53.6645C53.971 55.9989 54.2747 55.9397 54.5583 55.8235C54.842 55.7073 55.0999 55.5363 55.3174 55.3204C55.535 55.1044 55.7078 54.8477 55.8261 54.565C55.9444 54.2822 56.0059 53.9789 56.0069 53.6724C56.0082 53.3645 55.9484 53.0593 55.8311 52.7745C55.7138 52.4898 55.5413 52.2311 55.3236 52.0133C55.1058 51.7955 54.8471 51.623 54.5623 51.5057C54.2776 51.3884 53.9724 51.3286 53.6645 51.3299H2.33575Z"
                        fill="#2B4BB5"
                      />
                      <path
                        d="M10.259 35.0012C7.19692 35.0012 4.66113 37.5369 4.66113 40.5991V53.6724C4.66222 53.9789 4.72368 54.2822 4.84199 54.565C4.96031 54.8477 5.13317 55.1044 5.35069 55.3203C5.56822 55.5362 5.82616 55.7072 6.10977 55.8234C6.39338 55.9397 6.69711 55.9989 7.00361 55.9977H16.3393C16.9553 55.9955 17.5455 55.7498 17.9811 55.3142C18.4167 54.8786 18.6624 54.2885 18.6646 53.6724V40.5991C18.6646 37.5369 16.1287 35.0012 13.0666 35.0012H10.259Z"
                        fill="#2B4BB5"
                      />
                      <path
                        d="M26.6048 27.0607C23.5427 27.0607 21.0068 29.5964 21.0068 32.6587V53.6725C21.009 54.2885 21.2547 54.8787 21.6903 55.3143C22.1259 55.7499 22.7161 55.9956 23.3321 55.9977H32.6678C33.2838 55.9956 33.874 55.7499 34.3096 55.3143C34.7452 54.8787 34.9909 54.2885 34.9931 53.6725V32.6587C34.9931 29.5964 32.4572 27.0607 29.3951 27.0607H26.6048Z"
                        fill="#2B4BB5"
                      />
                      <path
                        d="M42.9339 20.0676C39.8718 20.0676 37.3359 22.6034 37.3359 25.6656V53.6725C37.3381 54.2885 37.5838 54.8787 38.0194 55.3143C38.4551 55.7499 39.0453 55.9956 39.6613 55.9978H48.9969C49.3034 55.9989 49.6071 55.9397 49.8908 55.8235C50.1744 55.7073 50.4323 55.5363 50.6499 55.3204C50.8674 55.1045 51.0403 54.8478 51.1586 54.565C51.2769 54.2823 51.3384 53.979 51.3395 53.6725V25.6656C51.3395 22.6034 48.7864 20.0676 45.7243 20.0676H42.9339Z"
                        fill="#2B4BB5"
                      />
                      <path
                        d="M40.7476 0.00219735C40.1312 0.00339516 39.5402 0.248507 39.1039 0.683993C38.6675 1.11948 38.4213 1.70995 38.4189 2.32642C38.4177 2.63322 38.477 2.93724 38.5934 3.22109C38.7099 3.50494 38.8811 3.76304 39.0974 3.98062C39.3137 4.19819 39.5708 4.37098 39.854 4.48907C40.1372 4.60717 40.4408 4.66827 40.7476 4.66886H43.1584C29.9618 16.0147 16.1439 23.0626 1.90583 25.7008C1.29888 25.8158 0.7623 26.1667 0.413746 26.6768C0.0651922 27.1868 -0.066889 27.8142 0.0464637 28.4215C0.157432 29.028 0.503969 29.566 1.01035 29.9178C1.51673 30.2696 2.14179 30.4067 2.74893 30.2991C18.0793 27.4585 32.8298 19.8408 46.6721 7.82707V10.5751C46.6709 10.8815 46.7301 11.1852 46.8462 11.4687C46.9624 11.7523 47.1333 12.0102 47.3491 12.2277C47.5649 12.4452 47.8214 12.6181 48.1041 12.7365C48.3867 12.8548 48.6899 12.9164 48.9963 12.9176C49.3043 12.9188 49.6094 12.859 49.8942 12.7417C50.1789 12.6244 50.4376 12.4519 50.6554 12.2342C50.8731 12.0164 51.0456 11.7577 51.1629 11.473C51.2802 11.1882 51.34 10.8831 51.3388 10.5751V3.70272C51.3392 1.68883 49.6339 0.00175641 47.62 0.00219735H40.7476Z"
                        fill="#2B4BB5"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_417_4638">
                        <rect width="56" height="56" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <Card className="lastpanelcard">
                    <CardBody>
                      <p className="mb-0">
                        Grow Your Business, Expand Your Network and Achieve
                        Financial Success with a Trusted Brand
                      </p>
                    </CardBody>
                  </Card>
                  <p className="pargraph mt-3 mb-0">
                    Don't miss this golden opportunity!
                  </p>
                  <p className="txtstyle">
                    Let Genivis Remedies help you achieve your entrepreneurial
                    dreams
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
}
