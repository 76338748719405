import React, { useState } from 'react'
import Layout from './Layout'
import santoshshendge from "../assets/images/santoshshendge.webp";
import bhagyashree from "../assets/images/administrative/bhagyashree.webp";
import pallvi from "../assets/images/administrative/pallvi.webp";
import sarang from "../assets/images/administrative/sarang.webp";
import prathmesh from "../assets/images/administrative/prathmesh.webp";
import tejaswini from "../assets/images/administrative/tejaswini.webp";
import tanmay from "../assets/images/administrative/tanmay.webp";
import pbhujbal from "../assets/images/administrative/pbhujbal.webp";
import avtar1 from "../assets/images/business/avtar1.webp";
import Select from "react-select";
import { statedropdown } from "../helpers";
import { Row, Col, Container, Form, Card } from "react-bootstrap";
export default function AdministrativeTeam() {
  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);
  const defaultOption = {
    value: "administrative",
    label: "Administrative Team",
  };
  const [selectedTeam, setSelectedTeam] = useState(defaultOption);

  const handleTeamChange = (selectedOption) => {
    setSelectedTeam(selectedOption);
  };

  const teamOptions = [
    { value: "administrative", label: "Administrative Team" },
    { value: "warehouse", label: "Warehouse Team" },
  ];

  const teamMembers = {
    administrative: [
      {
        name: "Shraddha Shilwant",
        position: "Account Executive",
        image: avtar1,
      },
      {
        name: "Bhagyashree Joshi",
        position: "HR & Admin Officer",
        image: bhagyashree,
      },

      {
        name: "Pallavi Pawar",
        position: "Business Development Executive",
        image: pallvi,
      },
      {
        name: "Sarang Musande",
        position: "Business Development Executive",
        image: sarang,
      },

      { name: "Prathmesh Giri", position: "Video Editor", image: prathmesh },
      {
        name: "Tejaswini Shinde",
        position: "Content Writer/Digital Marketing Officer",
        image: tejaswini,
      },
      { name: "Tanmay Bade", position: "Graphic Designer", image: tanmay },
      {
        name: "Pramod Bhujbal",
        position: "Senior Accounts Officer",
        image: pbhujbal,
      },
    ],

    warehouse: [
      // { name: 'Deepika Jain', position: 'Sales Consultant', image: deepika },
    ],
  };
  return (
    <Layout>
      <Container fluid>
        <div className={`${isSafari === true ? "aboutsafari" : "about-style"}`}>
          <div className="about p-5">
            <Row className="justify-content-center">
              <Col lg="9">
                <p className="text-center mb-0">Administrative Team</p>
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <Row className="justify-content-center">
              <Col lg="9">
                <Row>
                  <Col lg="4" md="4" sm="12" xs="12" className="text-center">
                    <img
                      src={santoshshendge}
                      alt="santosh shendge"
                      className="adminstyle"
                      loading="lazy"
                    />
                  </Col>
                  {/* <Col lg="1"></Col> */}
                  <Col lg="8" md="8" sm="12" xs="12" className="ps-5">
                    <p className="head text-start">
                      More About Our{" "}
                      <span style={{ color: "#2B4BB6" }}>Founder & CEO</span>{" "}
                    </p>
                    <p className="text-style">
                      Santosh Shedge Sir, the proud Founder and CEO of Genivis
                      Remedies, has established a remarkable legacy as a leading
                      figure in India's pharmaceutical sector.Renowned for
                      launching the fastest-growing chain in Generic Medicines
                      and Manufacturing Distribution since 2016, Santosh Shedge
                      Sir has propelled the company to new heights with over
                      2000 franchises across Maharashtra, Karnataka, Gujarat and
                      Odisha. With a steadfast commitment to healthcare
                      affordability and inspired by initiatives like the PM
                      Janaushadhi Yojana Scheme, he has made significant strides
                      in making healthcare accessible to all.
                    </p>
                    <p className="text-style">
                      Genivis Remedies thrives on an innovative low-cost
                      franchise model that attracts a diverse range of
                      entrepreneurs, reflecting Santosh Shedge Sir's vision for
                      a healthier tomorrow. His extensive experience, including
                      leadership roles in multinational giants like Bajaj
                      Allianz, TATA AIA, Reliance Nippon and Edelweiss,
                      underscores his capability to lead Genivis Remedies
                      towards its ambitious goal of 10,000 franchises by 2030.
                      Recognized for his pioneering leadership and award-winning
                      innovation, Santosh Shedge Sir is a distinguished figure
                      in the pharmaceutical industry, dedicated to mentoring and
                      inspiring future leaders.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="pt-4" style={{ backgroundColor: "#f9f9f9" }}>
            <Row className="justify-content-center">
              <Col lg="9">
                <Row className="justify-content-center">
                  <Col lg="8" className="text-center">
                    <p className="subhead ">Get to know our team</p>
                    <p className="text-style">
                      We’re committed to building a diverse and inclusive team.
                      Get to know the amazing individuals who are creating our
                      product and supporting our customers.
                    </p>
                    <Row className="justify-content-center">
                      <Col lg="3">
                        <Form.Group>
                          <Select
                            className="selectTo"
                            isClearable={true}
                            styles={statedropdown}
                            placeholder="Administrative Team"
                            name="team"
                            id="team"
                            options={teamOptions}
                            onChange={handleTeamChange}
                            defaultValue={defaultOption}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {selectedTeam && (
                  <Row className="mt-4">
                    {teamMembers[selectedTeam.value].map((member, index) => (
                      <Col key={index} lg="3" md="3" sm="6" xs="6">
                        <Card
                          style={{
                            border: "transparent",
                            backgroundColor: "transparent",
                          }}
                        >
                          <Card.Img
                            variant="top"
                            src={member.image}
                            className="admin-img"
                            alt="img"
                          />
                          <Card.Body>
                            <Card.Title className="adminhead">
                              {member.name}
                            </Card.Title>
                            <Card.Text className="admintxt">
                              {member.position}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
