import React from 'react'
import Layout from './Layout'
import { Row, Col, Container, Card, CardBody } from "react-bootstrap";
import one from "../assets/images/one.png";
import two from "../assets/images/two.png";
import three from "../assets/images/three.png";
import four from "../assets/images/four.png";
import five from "../assets/images/five.png";
import six from "../assets/images/six.png";

export default function Careers() {
  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);
  return (
    <>
      {/* <Helmet>
        <title>Genivis Remedies - Careers</title>
        <meta name="description" content="Get info about Generic Medicines" />
        <meta name="keywords" content="medicine, Generic medicine" />
      </Helmet> */}
      <Layout>
        <Container fluid>
          <div
            className={`${isSafari === true ? "whyussafari" : "whyus-style"}`}
          >
            <div className="about p-5">
              <Row className="justify-content-center">
                <Col lg="9">
                  <p className="text-center mb-0">Careers</p>
                </Col>
              </Row>
            </div>
            <Row className="justify-content-center py-5">
              <Col lg="9">
                <Row>
                  <Col lg="12">
                    <Card className="cardstyle">
                      <CardBody>
                        <Row>
                          <Col
                            lg="1"
                            md="1"
                            sm="12"
                            xs="12"
                            className="text-center"
                          >
                            <img
                              src={one}
                              className="imgstyle"
                              loading="lazy"
                              alt="one"
                            />
                          </Col>
                          <Col lg="4" md="4" sm="12" xs="12">
                            <p className="headstyle mb-0">
                              Unparalleled Growth and Reach
                            </p>
                          </Col>
                          <Col lg="7" md="7" sm="12" xs="12">
                            <p className="textstyle mb-0">
                              As India's No. 1 fastest-growing generic brand,
                              Genivis Remedies boasts over 2000 registered
                              stores, ensuring a strong and expanding presence
                              in key states like Maharashtra, Karnataka and Goa.
                            </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="12">
                    <Card className="cardstyle mt-4">
                      <CardBody>
                        <Row>
                          <Col
                            lg="1"
                            md="1"
                            sm="12"
                            xs="12"
                            className="text-center"
                          >
                            <img
                              src={two}
                              className="imgstyle"
                              loading="lazy"
                              alt="two"
                            />
                          </Col>
                          <Col lg="4" md="4" sm="12" xs="12">
                            <p className="headstyle mb-0">
                              Commitment to Affordable Healthcare
                            </p>
                          </Col>
                          <Col lg="7" md="7" sm="12" xs="12">
                            <p className="textstyle mb-0">
                              We are dedicated to making high-quality,
                              affordable generic medicines accessible to
                              everyone, aligning with our mission to provide
                              cost-effective healthcare solutions across India.
                            </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="12">
                    <Card className="cardstyle mt-4">
                      <CardBody>
                        <Row>
                          <Col
                            lg="1"
                            md="1"
                            sm="12"
                            xs="12"
                            className="text-center"
                          >
                            <img
                              src={three}
                              className="imgstyle"
                              loading="lazy"
                              alt="three"
                            />
                          </Col>
                          <Col lg="4" md="4" sm="12" xs="12">
                            <p className="headstyle mb-0">
                              Comprehensive Franchise Support
                            </p>
                          </Col>
                          <Col lg="7" md="7" sm="12" xs="12">
                            <p className="textstyle mb-0">
                              Franchisees benefit from extensive resources,
                              comprehensive support and the backing of a trusted
                              brand, making it easier to establish and grow
                              their business in the pharmaceutical sector.
                            </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="12">
                    <Card className="cardstyle mt-4">
                      <CardBody>
                        <Row>
                          <Col
                            lg="1"
                            md="1"
                            sm="12"
                            xs="12"
                            className="text-center"
                          >
                            <img
                              src={four}
                              className="imgstyle"
                              loading="lazy"
                              alt="four"
                            />
                          </Col>
                          <Col lg="4" md="4" sm="12" xs="12">
                            <p className="headstyle mb-0">
                              Innovative Approach to Healthcare
                            </p>
                          </Col>
                          <Col lg="7" md="7" sm="12" xs="12">
                            <p className="textstyle mb-0">
                              Our strategic growth plans include improving
                              pharmaceutical manufacturing processes and drug
                              delivery models, ensuring we remain at the
                              forefront of the industry with innovative
                              solutions.
                            </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="12">
                    <Card className="cardstyle mt-4">
                      <CardBody>
                        <Row>
                          <Col
                            lg="1"
                            md="1"
                            sm="12"
                            xs="12"
                            className="text-center"
                          >
                            <img
                              src={five}
                              className="imgstyle"
                              loading="lazy"
                              alt="five"
                            />
                          </Col>
                          <Col lg="4" md="4" sm="12" xs="12">
                            <p className="headstyle mb-0">
                              Strong Values and Quality Assurance
                            </p>
                          </Col>
                          <Col lg="7" md="7" sm="12" xs="12">
                            <p className="textstyle mb-0">
                              We procure high-quality medicines from WHO/GMP
                              certified manufacturers, ensuring consistency,
                              health effectiveness, and compliance with
                              appropriate standards, which underscores our
                              commitment to excellence.
                            </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="12">
                    <Card className="cardstyle mt-4">
                      <CardBody>
                        <Row>
                          <Col
                            lg="1"
                            md="1"
                            sm="12"
                            xs="12"
                            className="text-center"
                          >
                            <img
                              src={six}
                              className="imgstyle"
                              loading="lazy"
                              alt="six"
                            />
                          </Col>
                          <Col lg="4" md="4" sm="12" xs="12">
                            <p className="headstyle mb-0">
                              Vision for a Healthier Future
                            </p>
                          </Col>
                          <Col lg="7" md="7" sm="12" xs="12">
                            <p className="textstyle mb-0">
                              Our vision is to build the strongest chain of
                              generic medicine franchises with excellent
                              customer service and a noble social cause, aiming
                              to enhance healthcare accessibility nationwide.
                            </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </Layout>
    </>
  );
}
