import React, { useState } from 'react'
import { Row, Col, Modal } from "react-bootstrap";
import Slider from "react-slick";
import mact1 from "../assets/images/marketingf/mact1.webp";
import mact2 from "../assets/images/marketingf/mact2.webp";
import mact3 from "../assets/images/marketingf/mact3.webp";
import mact4 from "../assets/images/marketingf/mact4.webp";
import mact5 from "../assets/images/marketingf/mact5.webp";
import mact6 from "../assets/images/marketingf/mact6.webp";
import mact7 from "../assets/images/marketingf/mact7.webp";
import mact8 from "../assets/images/marketingf/mact8.webp";
import mact9 from "../assets/images/marketingf/mact9.webp";
import mact10 from "../assets/images/marketingf/mact10.webp";
import mact11 from "../assets/images/marketingf/mact11.webp";
import mact12 from "../assets/images/marketingf/mact12.webp";
import mact13 from "../assets/images/marketingf/mact13.webp";
import Sandip1 from "../assets/images/marketingf/Sandip1.webp";
import Sandip2 from "../assets/images/marketingf/Sandip2.webp";
import Sandip3 from "../assets/images/marketingf/Sandip3.webp";
import Sandip5 from "../assets/images/marketingf/Sandip5.webp";
import Sandip6 from "../assets/images/marketingf/Sandip6.webp";
import Suvidha1 from "../assets/images/marketingf/Suvidha1.jpeg";
import Suvidha3 from "../assets/images/marketingf/Suvidha3.webp";
export default function MarketingActivity() {
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const handleShow = (image) => {
        setSelectedImage(image);
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
        setSelectedImage('');
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    autoplay: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    autoplay: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                }
            }
        ]
    };
    return (
      <div>
        <p className="subhead text-start">Marketing Activity</p>
        <Row className="justify-content-center sliderdivtraining mb-4">
          <Col lg="9">
            <p className="ffamily text-center pt-4">Spotlight</p>
            <Slider {...settings} className="">
              <Row>
                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                  <img
                    src={mact7}
                    className="sliderimgmarketing"
                    alt="mact7"
                    loading="lazy"
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                  <img
                    src={mact8}
                    className="sliderimgmarketing"
                    alt="mact8"
                    loading="lazy"
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                  <img
                    src={mact9}
                    className="sliderimgmarketing"
                    alt="mact9"
                    loading="lazy"
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                  <img
                    src={mact10}
                    className="sliderimgmarketing"
                    alt="mact10"
                    loading="lazy"
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                  <img
                    src={mact11}
                    className="sliderimgmarketing"
                    alt="mact11"
                    loading="lazy"
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                  <img
                    src={mact12}
                    className="sliderimgmarketing"
                    alt="mact12"
                    loading="lazy"
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                  <img
                    src={mact13}
                    className="sliderimgmarketing"
                    alt="mact13"
                    loading="lazy"
                  />
                </Col>
              </Row>
            </Slider>
          </Col>
        </Row>
        <div className="mb-4">
          <Row className="justify-content-center">
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={mact1}
                className="img-style"
                onClick={() => handleShow(mact1)}
                alt="mact1"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={mact2}
                className="img-style"
                onClick={() => handleShow(mact2)}
                alt="mact2"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={mact3}
                className="img-style"
                onClick={() => handleShow(mact3)}
                alt="mact3"
                loading="lazy"
              />
            </Col>
          </Row>
          <Row className="mt-4 justify-content-center">
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={mact4}
                className="img-style"
                onClick={() => handleShow(mact4)}
                alt="mact4"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={mact5}
                className="img-style"
                onClick={() => handleShow(mact5)}
                alt="mact5"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={mact6}
                className="img-style"
                onClick={() => handleShow(mact6)}
                alt="mact6"
                loading="lazy"
              />
            </Col>
          </Row>
          <Row className="mt-4 justify-content-center">
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={Sandip1}
                className="img-style"
                onClick={() => handleShow(Sandip1)}
                alt="sandip1"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={Sandip2}
                className="img-style"
                onClick={() => handleShow(Sandip2)}
                alt="sandip2"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={Sandip3}
                className="img-style"
                onClick={() => handleShow(Sandip3)}
                alt="sandip3"
                loading="lazy"
              />
            </Col>
          </Row>
          <Row className="mt-4 justify-content-center">
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={Sandip5}
                className="img-style"
                onClick={() => handleShow(Sandip5)}
                alt="sandip5"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={Sandip6}
                className="img-style"
                onClick={() => handleShow(Sandip6)}
                alt="sandip6"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={Suvidha1}
                className="img-style"
                onClick={() => handleShow(Suvidha1)}
                alt="suvidha1"
                loading="lazy"
              />
            </Col>
          </Row>
          <Row className="mt-4 justify-content-center">
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={Suvidha3}
                className="img-style"
                onClick={() => handleShow(Suvidha3)}
                alt="suvidha3"
                loading="lazy"
              />
            </Col>
          </Row>
          <Modal show={show} onHide={handleClose} centered className="modal-lg">
            <Modal.Body className="text-center">
              <img src={selectedImage} alt="Selected" className="img-fluid" />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
}
