import React, { useState } from 'react';
import {
    Button, Row, Col, Container, Modal
} from "react-bootstrap";
import Layout from './Layout';

import gallery2 from "../assets/images/franchise/gallery2.webp";
import gallery3 from "../assets/images/franchise/gallery3.webp";
import gallery4 from "../assets/images/franchise/gallery4.webp";
import gallery5 from "../assets/images/franchise/gallery5.webp";
import gallery6 from "../assets/images/franchise/gallery6.webp";
import gallery7 from "../assets/images/franchise/gallery7.webp";
import gallery8 from "../assets/images/franchise/gallery8.webp";
import gallery10 from "../assets/images/franchise/gallery10.webp";
import gallery12 from "../assets/images/franchise/gallery12.webp";
import gallery14 from "../assets/images/franchise/gallery14.webp";
import gallery15 from "../assets/images/franchise/gallery15.webp";
import gallery16 from "../assets/images/franchise/gallery16.webp";
import gallery17 from "../assets/images/franchise/gallery17.webp";
import gallery18 from "../assets/images/franchise/gallery18.webp";
import gallery19 from "../assets/images/franchise/gallery19.webp";
import gallery20 from "../assets/images/franchise/gallery20.webp";
import sliderimg1 from "../assets/images/franchise/sliderimg1.webp";
import sliderimg2 from "../assets/images/franchise/sliderimg2.webp";
import img1 from "../assets/images/franchise/img1.webp";
import img2 from "../assets/images/franchise/img2.webp";
import img3 from "../assets/images/franchise/img3.webp";
import sliderimg4 from "../assets/images/franchise/sliderimg4.webp";
import Slider from "react-slick";

import TrainingProgram from "./TrainingProgram";
import PressRelease from "./PressRelease";
import AwardsRecognition from "./AwardsRecognition";
import MarketingActivity from "./MarketingActivity";
import OurWarehouse from "./OurWarehouse";
import GenericMedicineAwareness from "./GenericMedicineAwareness";
import Advertisement from "./Advertisement";
import FranchiseModelDemo from "./FranchiseModelDemo";
import { useLocation, useNavigate } from "react-router-dom";

export default function FranchiseInauguration() {
  const navigate = useNavigate();
  const location = useLocation();
  const { galleryType } = location.state || {};
  const [activeButton, setActiveButton] = useState({ galleryType });
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleShow = (image) => {
    setSelectedImage(image);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedImage("");
  };
  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const renderContent = () => {
    console.warn("galleryType->>>>", galleryType);

    switch (galleryType) {
      //Franchise Inauguration
      case "":
      case "franchiseInauguration":
        return (
          <>
            <p className="subhead text-start">Franchise Inaugurations</p>
            <Row className="justify-content-center sliderdiv mb-4">
              <Col lg="9">
                <p className="ffamily text-center pt-4">Spotlight</p>
                <Slider {...settings} className="">
                  <Row>
                    <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                      <img
                        src={sliderimg1}
                        className="sliderimg"
                        alt="slider1"
                        loading="lazy"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                      <img
                        src={sliderimg2}
                        className="sliderimg"
                        alt="slider2"
                        loading="lazy"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                      <img src={gallery4} className="sliderimg" alt="slider3" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                      <img
                        src={sliderimg4}
                        className="sliderimg"
                        alt="slider4"
                        loading="lazy"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                      <img
                        src={img1}
                        className="sliderimg"
                        alt="slider5"
                        loading="lazy"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                      <img
                        src={img2}
                        className="sliderimg"
                        alt="slider6"
                        loading="lazy"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                      <img
                        src={img3}
                        className="sliderimg"
                        alt="slider7"
                        loading="lazy"
                      />
                    </Col>
                  </Row>
                </Slider>
              </Col>
            </Row>
            <div className="mb-4">
              <Row className="justify-content-center">
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery2}
                    className="img-style"
                    onClick={() => handleShow(gallery2)}
                    alt="fri1"
                    loading="lazy"
                  />
                </Col>
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery3}
                    className="img-style"
                    onClick={() => handleShow(gallery3)}
                    alt="fri2"
                    loading="lazy"
                  />
                </Col>
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery6}
                    className="img-style"
                    onClick={() => handleShow(gallery6)}
                    alt="fri3"
                    loading="lazy"
                  />
                </Col>
              </Row>
              <Row className="mt-4 justify-content-center">
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery4}
                    className="img-style"
                    onClick={() => handleShow(gallery4)}
                    alt="fri4"
                    loading="lazy"
                  />
                </Col>
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery5}
                    className="img-style"
                    onClick={() => handleShow(gallery5)}
                    alt="fri5"
                    loading="lazy"
                  />
                </Col>
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery7}
                    className="img-style"
                    onClick={() => handleShow(gallery7)}
                    alt="fri6"
                    loading="lazy"
                  />
                </Col>
              </Row>
              <Row className="mt-4 justify-content-center">
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery8}
                    className="img-style"
                    onClick={() => handleShow(gallery8)}
                    alt="fri7"
                    loading="lazy"
                  />
                </Col>
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery10}
                    className="img-style"
                    onClick={() => handleShow(gallery10)}
                    alt="fri8"
                    loading="lazy"
                  />
                </Col>
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery12}
                    className="img-style"
                    onClick={() => handleShow(gallery12)}
                    alt="fri9"
                    loading="lazy"
                  />
                </Col>
              </Row>
              <Row className="mt-4 justify-content-center">
                {/* <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery13} className='img-style' onClick={() => handleShow(gallery13)} />
                                </Col> */}
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery14}
                    className="img-style"
                    onClick={() => handleShow(gallery14)}
                    alt="fri10"
                    loading="lazy"
                  />
                </Col>
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery15}
                    className="img-style"
                    onClick={() => handleShow(gallery15)}
                    alt="fri11"
                    loading="lazy"
                  />
                </Col>
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery16}
                    className="img-style"
                    onClick={() => handleShow(gallery16)}
                    alt="fri12"
                    loading="lazy"
                  />
                </Col>
              </Row>
              <Row className="mt-4 justify-content-center">
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery17}
                    className="img-style"
                    onClick={() => handleShow(gallery17)}
                    alt="fri13"
                    loading="lazy"
                  />
                </Col>
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery18}
                    className="img-style"
                    onClick={() => handleShow(gallery18)}
                    alt="fri14"
                    loading="lazy"
                  />
                </Col>
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery19}
                    className="img-style"
                    onClick={() => handleShow(gallery19)}
                    alt="fri15"
                    loading="lazy"
                  />
                </Col>
              </Row>
              <Row className="mt-4 justify-content-center">
                <Col lg="4" md="4" sm="6" xs="6">
                  <img
                    src={gallery20}
                    className="img-style"
                    onClick={() => handleShow(gallery20)}
                    alt="fri16"
                    loading="lazy"
                  />
                </Col>
              </Row>
              <Modal
                show={show}
                onHide={handleClose}
                centered
                className="modal-lg"
              >
                <Modal.Body className="text-center">
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="img-fluid"
                  />
                </Modal.Body>
              </Modal>
            </div>
          </>
        );
      //training
      case "trainingProgram":
        return (
          <>
            <TrainingProgram />
          </>
        );
      case "awardRecognition":
        return (
          <>
            <AwardsRecognition />
          </>
        );
      case "advertisementVideos":
        return (
          <>
            <Advertisement />
          </>
        );
      case "genericMedicineAwareness":
        return (
          <>
            <GenericMedicineAwareness />
          </>
        );
      case "franchiseModelDemo":
        return <FranchiseModelDemo />;
      case "ourWarehouse":
        return (
          <>
            <OurWarehouse />
          </>
        );
      //Press Realese
      case "pressRelease":
        return (
          <>
            <PressRelease />
          </>
        );
      //Marketing
      case "marketingActivity":
        return (
          <>
            <MarketingActivity />
          </>
        );
      default:
        return null;
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };

  return (
    <Layout>
      <Container fluid>
        <div className="franchiseinauguration pt-4">
          <Row className="justify-content-center">
            <Col lg="9">
              <Row>
                <Col lg="3" md="3" sm="12" xs="12" className="">
                  <p className="head text-start">Gallery</p>
                  <div className="wrap d-lg-block d-md-flex d-sm-flex d-xs-flex">
                    <div>
                      <Button
                        id="franchiseInauguration"
                        className={`btnstyle me-2 ${
                          activeButton === "franchiseInauguration"
                            ? "active"
                            : ""
                        }`}
                        // onClick={() => handleButtonClick("franchiseInauguration")}

                        onClick={() =>
                          navigate("/franchiseInauguration", {
                            state: { galleryType: "franchiseInauguration" },
                          })
                        }
                      >
                        Franchise Inauguration
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="trainingProgram"
                        className={`btnstyle me-2 ${
                          activeButton === "trainingProgram" ? "active" : ""
                        }`}
                        // onClick={() => handleButtonClick("trainingProgram")}
                        onClick={() =>
                          navigate("/trainingProgram", {
                            state: { galleryType: "trainingProgram" },
                          })
                        }
                      >
                        Training Program
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="awardRecognition"
                        className={`btnstyle me-2 ${
                          activeButton === "awardRecognition" ? "active" : ""
                        }`}
                        // onClick={() => handleButtonClick("awardRecognition")}
                        onClick={() =>
                          navigate("/awardRecognition", {
                            state: { galleryType: "awardRecognition" },
                          })
                        }
                      >
                        Award & Recognition's
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="advertisementVideos"
                        className={`btnstyle me-2 ${
                          activeButton === "advertisementVideos" ? "active" : ""
                        }`}
                        // onClick={() => handleButtonClick("advertisementVideos")}
                        onClick={() =>
                          navigate("/advertisementVideos", {
                            state: { galleryType: "advertisementVideos" },
                          })
                        }
                      >
                        Advertisement Videos
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="genericMedicineAwareness"
                        className={`btnstyle me-2 ${
                          activeButton === "genericMedicineAwareness"
                            ? "active"
                            : ""
                        }`}
                        // onClick={() => handleButtonClick("genericMedicineAwareness")}
                        onClick={() =>
                          navigate("/genericMedicineAwareness", {
                            state: { galleryType: "genericMedicineAwareness" },
                          })
                        }
                      >
                        Generic Medicine Awareness
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="franchiseModelDemo"
                        className={`btnstyle me-2 ${
                          activeButton === "franchiseModelDemo" ? "active" : ""
                        }`}
                        // onClick={() => handleButtonClick("franchiseModelDemo")}
                        onClick={() =>
                          navigate("/franchiseModelDemo", {
                            state: { galleryType: "franchiseModelDemo" },
                          })
                        }
                      >
                        Franchise Model Demo
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="ourWarehouse"
                        className={`btnstyle me-2 ${
                          activeButton === "ourWarehouse" ? "active" : ""
                        }`}
                        // onClick={() => handleButtonClick("ourWarehouse")}
                        onClick={() =>
                          navigate("/ourWarehouse", {
                            state: { galleryType: "ourWarehouse" },
                          })
                        }
                      >
                        Our Warehouse
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="pressRelease"
                        className={`btnstyle me-2 ${
                          activeButton === "pressRelease" ? "active" : ""
                        }`}
                        // onClick={() => handleButtonClick("pressRelease")}
                        onClick={() =>
                          navigate("/pressRelease", {
                            state: { galleryType: "pressRelease" },
                          })
                        }
                      >
                        Press Release
                      </Button>
                    </div>
                    <div>
                      <Button
                        id="marketingActivity"
                        className={`btnstyle me-2 ${
                          activeButton === "marketingActivity" ? "active" : ""
                        }`}
                        // onClick={() => handleButtonClick("marketingActivity")}
                        onClick={() =>
                          navigate("/marketingActivity", {
                            state: { galleryType: "marketingActivity" },
                          })
                        }
                      >
                        Marketing Activity
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col lg="9" md="9" sm="12" xs="12">
                  {renderContent()}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
