import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useLocation } from "react-router-dom";
import call from "../assets/images/call.png";
import gmail from "../assets/images/gmail.png";
import santoshcontact from "../assets/images/santoshcontact.webp";
import directions from "../assets/images/directions.png";
import { Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandPointDown,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import MyMapComponent from "./MyMapComponent";

const css = `
  .custom-marker {
    position: relative;
    display: flex;
    align-items: center;
  }

  .custom-label {
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    margin-right: 10px; /* Adjust this value to position the label */
    font-size: 14px;
    font-weight: bold;
  }

  .marker-icon {
    width: 20px;
    height: 20px;
    background-color: red; /* Custom marker color */
    border-radius: 50%;
  }
`;

export default function Contact() {
  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);
  const location = useLocation();
  const [customLat, setCustomLat] = useState(18.5206987); // Default latitude
  const [customLng, setCustomLng] = useState(73.9302722); // Default longitude

  const setSiteLatLong = (lat, lng) => {
    setCustomLat(lat);
    setCustomLng(lng);
  };
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <Layout>
      <div className={`${isSafari === true ? "aboutsafari" : "about-style"}`}>
        <div className="about p-5">
          <Row className="justify-content-center">
            <Col lg="9">
              <p className="text-center mb-0">Contact Us</p>
            </Col>
          </Row>
        </div>
        <Row className="justify-content-center">
          <Col lg="9" className="pdmob">
            <Row className="mt-5">
              <Col lg="6" md="6" sm="12" xs="12">
                <p className="text-style" style={{ fontWeight: "600" }}>
                  We value your interest in our products and services. Whether
                  you have questions, feedback, or need assistance, our team is
                  here to help.
                </p>
                <Card className="contactstyle">
                  <p className=" contacthead">Head Office:</p>
                  <Row>
                    <Col lg="1" md="1" sm="1" xs="1" className="pe-2 text-end">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        className="contactico"
                        style={{ color: "#2B4BB6" }}
                      ></FontAwesomeIcon>
                    </Col>
                    <Col lg="11" md="11" sm="11" xs="11">
                      <p className="text-style" style={{ fontWeight: "600" }}>
                        Office No. 6070, 6th Floor, Marvel Fuego, Opp Seasons
                        Mall, Magarpatta Road, Hadapsar, Pune 411028.
                      </p>
                    </Col>
                  </Row>
                </Card>
                <Card className="contactstyle">
                  <a
                    href="tel:+919145009191"
                    style={{ textDecoration: "none" }}
                  >
                    <Row>
                      <p className=" contacthead">Call Us:</p>
                      <Col
                        lg="1"
                        md="1"
                        sm="1"
                        xs="1"
                        className="pe-0 text-end"
                      >
                        <img
                          src={call}
                          className="contactico me-2"
                          alt="call"
                        />
                      </Col>
                      <Col lg="11" md="11" sm="11" xs="11">
                        <p className="text-style" style={{ fontWeight: "600" }}>
                          +91 9145009191
                        </p>
                      </Col>
                    </Row>
                  </a>
                </Card>
                <Card className="contactstyle ">
                  <a
                    href="mailto:prm@genivis.in"
                    style={{ textDecoration: "none" }}
                  >
                    <Row>
                      <p className=" contacthead">Mail ID:</p>
                      <Col
                        lg="1"
                        md="1"
                        sm="1"
                        xs="1"
                        className="pe-0 text-end"
                      >
                        <img
                          src={gmail}
                          className="contactico me-2"
                          alt="gmail"
                        />
                      </Col>
                      <Col lg="11" md="11" sm="11" xs="11">
                        <p className="text-style" style={{ fontWeight: "600" }}>
                          prm@genivis.in
                        </p>
                      </Col>
                    </Row>
                  </a>
                </Card>
              </Col>
              <Col lg="6" md="6" sm="12" xs="12" className="text-center">
                <img
                  src={santoshcontact}
                  className="contactimg mb-4"
                  loading="lazy"
                  alt="contact"
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg="1" md="1" sm="2" xs="2" className="px-0">
                <div className="direction">
                  <img
                    src={directions}
                    className="dirimg"
                    alt="direction"
                    loading="lazy"
                  />
                </div>
              </Col>

              <Col
                lg="5"
                md="5"
                sm="4"
                xs="4"
                style={{
                  border: "1px solid #2B4BB6",
                  borderRight: "1px solid transparent",
                }}
              >
                <div className="">
                  <span>
                    <p className="directionhead mb-0 ">How To Reach Us?</p>
                  </span>
                </div>
              </Col>
              <Col
                lg="6"
                md="6"
                sm="5"
                xs="5"
                style={{
                  border: "1px solid #2B4BB6",
                  borderLeft: "1px solid transparent",
                }}
              >
                <div className="text-end">
                  <span>
                    <p className="directiontext mb-0">
                      You can reach by follow the direction
                      <FontAwesomeIcon
                        icon={faHandPointDown}
                        className="ms-2"
                        style={{ color: "gold" }}
                      />
                    </p>
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="px-0">
          <Col lg="12" className="px-0">
            <style>{css}</style>
            <MyMapComponent
              isMarkerShown
              customlat={customLat}
              customlng={customLng}
              setSiteLatLong={setSiteLatLong}
            />
          </Col>
        </Row>
      </div>
    </Layout>
  );
}
