import React, { useState, useRef } from 'react'
import Layout from './Layout';
import {
  Button,
  Row,
  Col,
  Card,
  Form,
  FormControl,
  Spinner,
} from "react-bootstrap";
import config from "config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import trustedbrands from "../assets/images/trustedbrands.webp";
import gmpcertifiedmedicine from "../assets/images/gmpcertifiedmedicine.webp";
import productrange from "../assets/images/productrange.webp";
import monopolyproducts from "../assets/images/monopolyproducts.webp";
import franchisestores from "../assets/images/franchisestores.webp";
import santoshshedge from "../assets/images/santoshshedge.webp";
import successfullyoperating from "../assets/images/successfullyoperating.webp";
import vector from "../assets/images/vector.png";
import bussiness2b from "../assets/images/bussiness2b.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiamond, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import sliderimg1 from "../assets/images/franchise/sliderimg1.webp";
import sliderimg2 from "../assets/images/franchise/sliderimg2.webp";
import img1 from "../assets/images/franchise/img1.webp";
import img2 from "../assets/images/franchise/img2.webp";
import img3 from "../assets/images/franchise/img3.webp";
import sliderimg4 from "../assets/images/franchise/sliderimg4.webp";
import gallery4 from "../assets/images/franchise/gallery4.webp";
import bannerlight from "@/assets/images/bannerlight.webp";
import businesspartner from "@/assets/images/businesspartner.webp";
import { useNavigate } from "react-router-dom";
export default function Franchise() {
  const navigate = useNavigate();
  const formRef = useRef(null);

  const initialFormData = {
    name: "",
    contact: "",
    email: "",
    city: "",
    subject: "",
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smooth scrolling
    });
  };
  const [formData, setFormData] = useState(initialFormData);

  const [errors, setErrors] = useState({});
  const [isDisabled, setDisabled] = useState(false);

  const sub = "Franchise Enquiry";
  // Function to handle input change and clear error when value exists
  const handleChange = (e) => {
    const { id, value } = e.target;
    // console.warn(" id, value->>>>>>>", { id, value });
    setFormData({ ...formData, [id]: value });

    // Clear error if the field is valid
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: null, // Clear error for this field
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "This field is required", // Mark as required if field is cleared
      }));
    }
  };
  const validateForm = () => {
    let formErrors = {};

    if (!formData.name) formErrors.name = "Name is required";
    if (!formData.contact) {
      formErrors.contact = "Contact number is required";
    } else if (!/^\d+$/.test(formData.contact)) {
      formErrors.contact = "Contact number must be numeric";
    }
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email address is invalid";
    }
    if (!formData.city) formErrors.city = "City is required";

    return formErrors;
  };

  const handleSubmi1t = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    console.warn("e.target.elements->>>>", formData);

    const { name, contact, email, city, subject } = formData;
    let details = {
      name: name.value,
      mobileno: contact.value,
      email: email.value,
      city: city.value,
      subject: subject.value,
    };
    if (Object.keys(formErrors).length === 0) {
      console.log("Form Data:", formData);
      // Clear the form
      setFormData(initialFormData);
      alert("Form submitted successfully!");

      try {
        let response = await fetch(`${config.emailServerUrl}/frinfo`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Corrected header
          },
          body: JSON.stringify(details),
        });

        let result = await response.json();
        console.log("result.status", result.status);
        // formRef.resetForm();
        setFormData({
          name: "",
          contact: "",
          email: "",
          city: "",
          subject: "",
        });
        formRef.current.reset();

        toast.success("Form Submitted Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error("Form Submission Failed", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      setErrors(formErrors);
    }
  };

  const validateField = (name, value) => {
    let error;
    switch (name) {
      case "name":
        if (!value) {
          error = "Name is required";
        } else if (!/^[a-zA-Z ]+$/.test(value)) {
          error = "Name can only contain letters and spaces";
        }
        break;
      case "contact":
        if (!value) {
          error = "Mobile number is required";
        } else if (!/^[0-9]{10}$/.test(value)) {
          error = "Mobile number must be 10 digits";
        }
        break;
      case "email":
        if (!value) {
          error = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = "Email is invalid";
        }
        break;
      case "city":
        if (!value) {
          error = "City is required";
        } else if (!/^[a-zA-Z ]+$/.test(value)) {
          error = "City can only contain letters and spaces";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, contact, email, city } = formData;
    let details = {
      name: name,
      mobileno: contact,
      email: email,
      city: city,
      subject: "Franchise Enquiry",
    };
    // Check for errors before submitting
    const validationErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) {
        validationErrors[key] = error;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setDisabled(true);
    try {
      let response = await fetch(`${config.emailServerUrl}/frinfo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Corrected header
        },
        body: JSON.stringify(details),
      });

      let result = await response.json();
      if (result.message === "success") {
        navigate("/thankyou");
        // formRef.resetForm();
        setFormData({
          name: "",
          contact: "",
          email: "",
          city: "",
          subject: "",
        });
        formRef.current.reset();
      } else {
        navigate("/errorEmail");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Form Submission Failed", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setDisabled(false);
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };
  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);
  return (
    <Layout>
      <div
        className={`${
          isSafari === true ? "testimonial-safari" : "testimonial-fra"
        }`}
      >
        <div className="home-style">
          <div
            className="safari"
            style={{
              backgroundImage: `url(${bannerlight})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <ToastContainer />
            <Row className="pt-5 ">
              <Col lg="5" md="5" sm="12" xs="12" className="pe-0">
                <Row className="">
                  <Col lg="2" md="2" sm="12" xs="12"></Col>
                  <Col lg="10" md="10" sm="12" xs="12" className="">
                    <p className="headtext">
                      <span style={{ color: "#2b4bb6" }}>
                        Genivis Remedies-India's No.1
                      </span>{" "}
                      Fastest Growing Generic Medicine Manufacturing and
                      Distribution Company
                    </p>
                    <p className="tagline">
                      Start Your Own Business,Maximize Your Profits!
                    </p>
                    <Row className="justify-content-center">
                      <Col className="mb-3">
                        <div
                          className="badgestyle"
                          style={{ width: "fit-content" }}
                        >
                          <FontAwesomeIcon icon={faDiamond} className="me-2" />
                          Low Investment
                          <span>
                            <b> High Profit</b>
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3">
                        <div
                          className="badgestylegreen"
                          style={{ width: "fit-content" }}
                        >
                          <FontAwesomeIcon icon={faDiamond} className="me-2" />
                          Start Your
                          <span>
                            <b> Own Business</b>
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="">
                        <div
                          className="badgestyle"
                          style={{ width: "fit-content" }}
                        >
                          <FontAwesomeIcon icon={faDiamond} className="me-2" />
                          <span>
                            <b>2000+</b>
                          </span>{" "}
                          Successful Franchise Stores
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col
                lg="3"
                md="3"
                sm="12"
                xs="12"
                className="justify-content-center"
              >
                <img
                  src={santoshshedge}
                  className="bnlandingimg"
                  loading="lazy"
                  alt="Santosh Shedge"
                />
              </Col>
              <Col lg="4" md="4" sm="12" xs="12">
                <Row className="justify-content-center">
                  <Col lg="8" className="px-lg-0 px-md-0 ">
                    <div className="styleform">
                      <Form
                        noValidate
                        onSubmit={handleSubmit}
                        className="about-style mt-0"
                        ref={formRef}
                      >
                        <div>
                          <p
                            className="subhead text-start mb-2"
                            style={{ color: "#2b4bb6" }}
                          >
                            Franchise Enquiry Form
                          </p>
                        </div>
                        <Form.Group className="mb-2">
                          {/* <Form.Label className='lblstyle'>Name</Form.Label> */}
                          <Form.Control
                            type="text"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleChange}
                            name="name"
                            id="name"
                            isInvalid={!!errors.name}
                            className="fieldstyle"
                            onKeyPress={(e) => {
                              var regex = new RegExp("^[a-zA-Z ]+$"); // Updated regex to allow spaces
                              var str = String.fromCharCode(
                                !e.charCode ? e.which : e.charCode
                              );
                              if (regex.test(str)) {
                                return true;
                              }
                              e.preventDefault();
                              return false;
                            }}
                          />
                          {errors.name && (
                            <FormControl.Feedback type="invalid">
                              {errors.name}
                            </FormControl.Feedback>
                          )}
                        </Form.Group>

                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          className="fieldstyle d-none"
                          name="subject"
                          id="subject"
                          value={sub}
                          onChange={handleChange}
                        />

                        <Form.Group className="mb-2">
                          {/* <Form.Label className='lblstyle'>Contact No.</Form.Label> */}
                          <Form.Control
                            type="text"
                            placeholder="Mobile No."
                            value={formData.contact}
                            onChange={handleChange}
                            className="fieldstyle"
                            onKeyPress={(e) => {
                              var regex = new RegExp("^[0-9]+$");
                              var str = String.fromCharCode(
                                !e.charCode ? e.which : e.charCode
                              );

                              if (regex.test(str)) {
                                return true;
                              }
                              e.preventDefault();
                              return false;
                            }}
                            name="contact"
                            id="contact"
                            maxLength={10}
                            isInvalid={!!errors.contact}
                          />
                          {errors.contact && (
                            <Form.Control.Feedback type="invalid">
                              {errors.contact}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-2">
                          {/* <Form.Label className='lblstyle'>Email address</Form.Label> */}
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            name="email"
                            id="email"
                            isInvalid={!!errors.email}
                            className="fieldstyle"
                          />
                          {errors.email && (
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-2">
                          {/* <Form.Label className='lblstyle'>City</Form.Label> */}
                          <Form.Control
                            type="text"
                            placeholder="City"
                            value={formData.city}
                            onChange={handleChange}
                            name="city"
                            id="city"
                            isInvalid={!!errors.city}
                            className="fieldstyle"
                          />
                          {errors.city && (
                            <Form.Control.Feedback type="invalid">
                              {errors.city}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <div className="aboutus">
                          <Button
                            className="w-100 franchisebtn"
                            onClick={handleSubmit}
                            disabled={isDisabled}
                          >
                            {isDisabled ? (
                              <>
                                <Spinner className="spinner_style" /> Submitting
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <div className="ourmilestone mt-5">
          <div className="slider-container">
            <Row className="justify-content-center mb-4">
              <Col className="justify-content-center" lg="9">
                <p className="head">
                  <span style={{ color: "black" }}>Why Choose</span> Genivis
                  Remedies Franchise?
                </p>
                <Row>
                  <Col>
                    <Card className="mb-3 whyuscard">
                      <Card.Body className="text-center content">
                        <img
                          variant="top"
                          src={trustedbrands}
                          className="whyusimg mb-3"
                          alt="trustedbrands"
                        />
                        {/* <Card.Title className='ffamily'>29th May 2024</Card.Title> */}
                        <Card.Subtitle className="mb-2 text-muted subtitlehead">
                          Trusted Brand
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mb-3 whyuscard">
                      <Card.Body className="text-center content">
                        <img
                          variant="top"
                          src={gmpcertifiedmedicine}
                          className="whyusimg mb-3"
                          alt="gmpcertifiedmedicine"
                        />

                        <Card.Subtitle className="mb-2 text-muted subtitlehead">
                          WHO GMP Certified Medicines
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mb-3 whyuscard">
                      <Card.Body className="text-center content">
                        <img
                          variant="top"
                          src={productrange}
                          className="whyusimg mb-3"
                          alt="productrange"
                        />
                        <Card.Subtitle className="mb-2 text-muted subtitlehead">
                          5000+ Product Range
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="mb-3 whyuscard">
                      <Card.Body className="text-center content">
                        <img
                          variant="top"
                          src={monopolyproducts}
                          className="whyusimg mb-3"
                          alt="monopolyproducts"
                        />
                        <Card.Subtitle className="mb-2 text-muted subtitlehead">
                          600+ Monopoly Products
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mb-3 whyuscard">
                      <Card.Body className="text-center content">
                        <img
                          variant="top"
                          src={successfullyoperating}
                          className="whyusimg mb-3"
                          alt="successfullyoperating"
                        />
                        <Card.Subtitle className="mb-2 text-muted subtitlehead">
                          Successfully Operating in 5 States
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mb-3 whyuscard">
                      <Card.Body className="text-center content">
                        <img
                          variant="top"
                          src={franchisestores}
                          className="whyusimg mb-3"
                          alt="franchisestores"
                        />
                        <Card.Subtitle className="mb-2 text-muted subtitlehead">
                          2000+ Successful Franchise Stores
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <div className="franchiseinauguration mt-0 mb-4">
          <Row className="justify-content-center">
            <Col lg="9" md="11" sm="12" xs="12">
              <p className="head">Franchise Inaugurations</p>
              <Row className="justify-content-center sliderdiv mb-4 mx-3">
                <Col lg="7">
                  <p className="ffamily text-center pt-4">Spotlight</p>
                  <Slider {...settings} className="">
                    <Row>
                      <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                        <img
                          src={sliderimg1}
                          className="sliderimg"
                          alt="sliderimg1"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                        <img
                          src={sliderimg2}
                          className="sliderimg"
                          alt="sliderimg2"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                        <img
                          src={gallery4}
                          className="sliderimg"
                          alt="gallery4"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                        <img
                          src={sliderimg4}
                          className="sliderimg"
                          alt="sliderimg4"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                        <img
                          src={img1}
                          className="sliderimg"
                          alt="franchise1"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                        <img
                          src={img2}
                          className="sliderimg"
                          alt="franchise2"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                        <img
                          src={img3}
                          className="sliderimg"
                          alt="franchise3"
                        />
                      </Col>
                    </Row>
                  </Slider>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="benifits mt-0">
          <Row className="justify-content-center">
            <Col lg="9" md="11" sm="12" xs="12">
              <p className="head">
                <span style={{ color: "black" }}>Franchise</span> Support
              </p>
              <div
                className="support"
                style={{
                  backgroundImage: `url(${businesspartner})`,
                }}
              >
                <Row className="justify-content-center h-100">
                  <Col lg="6" md="6" sm="12" xs="12" className="my-auto">
                    {/* <h1 className='text-white benifithead mb-4'>Franchise Support </h1> */}
                    <div className="d-flex">
                      <img src={vector} className="tick me-2" alt="vector" />
                      <p className="liststyle">Marketing Support</p>
                    </div>
                    <div className="d-flex">
                      <img src={vector} className="tick me-2" alt="vector" />
                      <p className="liststyle">Operational Support</p>
                    </div>
                    <div className="d-flex">
                      <img src={vector} className="tick me-2" alt="vector" />
                      <p className="liststyle">
                        Continuous Training on Sales & Business Development
                      </p>
                    </div>
                    <div className="d-flex">
                      <img src={vector} className="tick me-2" alt="vector" />
                      <p className="liststyle">
                        {" "}
                        Mobile App Facility for Customers
                      </p>
                    </div>
                    <div className="d-flex">
                      <img src={vector} className="tick me-2" alt="vector" />
                      <p className="liststyle">Credit Facility Available</p>
                    </div>
                  </Col>
                  <Col
                    lg="6"
                    md="6"
                    sm="12"
                    xs="12"
                    className="text-center my-auto"
                  >
                    <div className="img2">
                      <img
                        src={bussiness2b}
                        className="businessimg"
                        alt="vector"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <div className="ourmilestone">
          <div className="slider-container">
            <Row className="justify-content-center my-5">
              <Col className="justify-content-center" lg="9">
                <p className="head">
                  <span style={{ color: "black" }}>Franchise</span> Testimonial
                </p>
                <Row>
                  <Col lg="6" md="6" sm="12" xs="12" className="">
                    <div>
                      <Card className="cardstyle1">
                        <Card.Body>
                          <FontAwesomeIcon
                            icon={faQuoteLeft}
                            className="quote"
                          />
                          <div
                            className="d-flex"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Card.Title className="cardhead mb-2">
                              Kavita Gosavi
                            </Card.Title>
                            <p className="justify-content-end badgesince">
                              Partner Since:2018
                            </p>
                          </div>
                          <Card.Text className="cardtext">
                            Three years ago, I had the pleasure of collaborating
                            with Genivis Remedies (I) Pvt. Ltd. It is
                            commendable how much effort they put into customer
                            service, deliverables, and time management. Their
                            constant follow ups helped me increase my business
                            growth to 90% in just a few months. Genivis Remedies
                            (I) Pvt. Ltd. is the best platform and concept to
                            start a business and make a career. The wide variety
                            of elements they offer comes with zero shortages,
                            and their strong customer service is an additional
                            advantage.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                  <Col lg="6" md="6" sm="12" xs="12" className="">
                    <div>
                      <Card className="cardstyle1">
                        <Card.Body>
                          <FontAwesomeIcon
                            icon={faQuoteLeft}
                            className="quote"
                          />
                          <div
                            className="d-flex"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Card.Title className="cardhead mb-2">
                              Ashutosh Kadam
                            </Card.Title>
                            <p className="justify-content-end badgesince">
                              Partner Since:2017
                            </p>
                          </div>
                          <Card.Text className="cardtext">
                            We had a chance to be part of their interesting and
                            interactive team. Where we noticed drastic changes
                            in my business with their support. Engaging with
                            Genivis has been one of the best decisions in my
                            career. Their years of experience has proven real
                            growth from time to time.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <div className="enquiry">
          <Row className="justify-content-center mb-5">
            <Col className="justify-content-center" lg="9">
              <div className="bgenquery text-center">
                <p className="head mb-0">
                  <span style={{ color: "black" }}>Franchise</span> Enquiry
                </p>
                <p>Want to be a partner with us</p>
                <div className="aboutus">
                  <Button className="franchisebtn" onClick={scrollToTop}>
                    Enquiry Now
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
}
