import React, { useEffect, useState, useRef } from "react";
import {
  Nav,
  Button,
  Navbar,
  NavDropdown,
  Container,
  Row,
  Col,
  Overlay,
  Popover,
} from "react-bootstrap";
import ReactWhatsapp from "react-whatsapp";

import gvtmlogo from "../assets/images/gvtmlogo.webp";
import call from "../assets/images/call.png";
import gmail from "../assets/images/gmail.png";

import appstore from "../assets/images/appstore.webp";
import imageplay from "../assets/images/imageplay.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faSquareWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

export default function Layout(props) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  };
  useEffect(() => {
    // Add the event listener when the component is mounted
    document.addEventListener("mousedown", handleClickOutside);

    // Remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);
  return (
    <div style={{ overflowX: "hidden" }}>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={`${isSafari === true ? "navsafari" : "navbar-style"}`}
      >
        <Container>
          <Navbar.Brand
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            <div className="logostyle">
              <img src={gvtmlogo} className="logostyle" alt="genivis logo" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                eventKey={2}
                className="me-3 menustyle"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/");
                }}
              >
                Home
              </Nav.Link>
              <NavDropdown
                title={
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/aboutus");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    About
                  </span>
                }
                id="collapsible-nav-dropdown"
                className="me-3 menustyle"
              >
                <NavDropdown.Item
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/biggestmilestone");
                  }}
                >
                  Biggest Milestone
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/administrativeteam");
                  }}
                >
                  Administrative Team
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/businesspartner");
                  }}
                >
                  Business Partner
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                eventKey={3}
                className="me-3 menustyle"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/gallery", {
                    state: { galleryType: "" },
                  });
                }}
              >
                Gallery
              </Nav.Link>

              <NavDropdown
                id="collapsible-nav-dropdown"
                className="me-3 menustyle"
                title={
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/careers");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Careers
                  </span>
                }
              >
                <NavDropdown.Item
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/jobs");
                  }}
                >
                  Jobs
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                eventKey={4}
                className="me-3 menustyle"
                href="https://blogs.genivis.in/"
                target="_blank"
                rel="noopener noreferrer"
                // onClick={(e) => {
                //     e.preventDefault();
                //     navigate("/blogs");
                // }}
              >
                Blogs
              </Nav.Link>

              <Nav.Link
                eventKey={5}
                href=""
                className="me-3 menustyle"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/contact");
                }}
              >
                Contact
              </Nav.Link>
              <Nav.Link
                eventKey={6}
                href=""
                className="me-3 menustyle"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/franchise");
                }}
              >
                Franchise
              </Nav.Link>
            </Nav>
            <Nav style={{ flexDirection: "row" }}>
              <div className="">
                <Button
                  type="button"
                  className="btn-style my-auto"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/becomebusinessPartner");
                  }}
                >
                  Become a Business Partner
                </Button>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {props.children}
      <div
        // className="call_icon_style"
        onClick={() => document.getElementById("whatsapp-button").click()}
        style={{ display: "inline-block", cursor: "pointer" }}
      >
        <FontAwesomeIcon
          icon={faSquareWhatsapp}
          size="2x"
          className="whatsappstyle"
        />
        <ReactWhatsapp
          number="91 4500 9191"
          message="Hello!!!"
          id="whatsapp-button"
          style={{ display: "none", cursor: "pointer" }}
        />
      </div>
      <div className="call_icon_style">
        <a
          href="tel:+919145009191"
          style={{ textDecoration: "none" }}
          aria-label="Call +919145009191"
        >
          <img src={call} className="call" alt="Call +919145009191" />
        </a>
        <Overlay
          show={show}
          placement="bottom"
          container={ref}
          containerPadding={20}
        >
          <Popover
            id="popover-contained"
            style={{ backgroundColor: "#9DA5AC" }}
          >
            <Popover.Body style={{ padding: "5px" }}>
              <a href="tel:+919145009191" style={{ textDecoration: "none" }}>
                <strong style={{ color: "#fff" }}>+919145009191</strong>
              </a>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>

      <div className="footer">
        <Row className="justify-content-center">
          <Col lg="9">
            <hr style={{ marginTop: "0px" }} />
            <Row>
              <Col lg="6" md="6" sm="12" xs="12">
                <Row>
                  <Col lg="7" md="9" sm="12" xs="12">
                    <img
                      src={gvtmlogo}
                      className="footerlogo mb-4"
                      alt="Gv Logo"
                    />
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        className="m-2 icon-style"
                        style={{ color: "#2B4BB6" }}
                      />
                      <p className="footertxt">
                        Office No. 6070, 6th Floor, Marvel Fuego, Opp Seasons
                        Mall, Magarpatta Road, Hadapsar, Pune 411028.
                      </p>
                    </div>

                    <a
                      href="tel:+919145009191"
                      style={{ textDecoration: "none" }}
                    >
                      <Row className="mt-5">
                        <Col lg="1" sm="1" xs="1" className="pe-0">
                          <img
                            src={call}
                            className="icon-style"
                            alt="call icon"
                          />
                        </Col>
                        <Col lg="11" sm="11" xs="11">
                          <p className="contact-style">+919145009191</p>
                        </Col>
                      </Row>
                    </a>

                    <a
                      href="mailto:prm@genivis.in"
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col lg="1" sm="1" xs="1" className="pe-0">
                          <img
                            src={gmail}
                            className="icon-style"
                            alt="gmail icon"
                          />
                        </Col>
                        <Col lg="11" sm="11" xs="11">
                          <p className="contact-style">prm@genivis.in</p>
                        </Col>
                      </Row>
                    </a>
                  </Col>
                </Row>
              </Col>
              {/* <Col lg="1" md="1"></Col> */}
              <Col
                lg="3"
                md="3"
                sm="6"
                xs="6"
                className="justify-content-center pe-0"
              >
                <ul
                  style={{ listStyleType: "none" }}
                  className="ps-3 liststyle"
                >
                  <li className="fthead mb-2">Company</li>
                  <li
                    className="footertxt mb-2"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Home
                  </li>
                  <li
                    className="footertxt mb-2"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/aboutus");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    About Us
                  </li>
                  <li
                    className="footertxt mb-2"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/gallery");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Gallery
                  </li>
                  <li
                    className="footertxt mb-2"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/jobs");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Careers
                  </li>
                  <li
                    className="footertxt mb-2"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/contact");
                    }}
                  >
                    Contact Us
                  </li>
                  <li
                    className="footertxt mb-2"
                    // onClick={(e) => {
                    //     e.preventDefault();
                    //     navigate("/blogs");
                    // }}
                    style={{ cursor: "pointer" }}
                  >
                    <a
                      href="https://blogs.genivis.in/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                      className="footertxt"
                    >
                      Blogs
                    </a>
                  </li>
                  <li
                    className="footertxt"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/privacy");
                    }}
                  >
                    Privacy Policy
                  </li>
                </ul>
              </Col>

              <Col
                lg="3"
                md="3"
                sm="6"
                xs="6"
                className="justify-content-center pe-0"
              >
                <ul
                  style={{ listStyleType: "none" }}
                  className="ps-3 liststyle"
                >
                  <li className="fthead mb-2">Follow Us</li>
                  <li className="footertxt mb-2" style={{ cursor: "pointer" }}>
                    <a
                      href="https://www.facebook.com/GenivisRemedies"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                      className="footertxt"
                    >
                      <FontAwesomeIcon icon={faFacebook} className="me-2" />
                      Facebook
                    </a>
                  </li>
                  <li className="footertxt mb-2" style={{ cursor: "pointer" }}>
                    <a
                      href="https://www.instagram.com/genivisremedies?igsh=a2R6MnAwNmV0aGht"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                      className="footertxt"
                    >
                      <FontAwesomeIcon icon={faInstagram} className="me-2" />
                      Instagram
                    </a>
                  </li>
                  <li className="footertxt mb-2" style={{ cursor: "pointer" }}>
                    <a
                      href="https://www.linkedin.com/company/100572660/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                      className="footertxt"
                    >
                      <FontAwesomeIcon icon={faLinkedin} className="me-2" />
                      Linkedin
                    </a>
                  </li>
                  <li className="footertxt mb-2" style={{ cursor: "pointer" }}>
                    <a
                      href="https://www.youtube.com/@genivisremedies/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                      className="footertxt"
                    >
                      <FontAwesomeIcon icon={faYoutube} className="me-2" />
                      Youtube
                    </a>
                  </li>
                  {/* <li className='footertxt mb-2'>
                                        <FontAwesomeIcon icon={faSquareXTwitter} className='me-2' />
                                        X(Twitter)
                                    </li> */}
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col lg="12" className="justify-content-center">
                <div className="footerdiv text-center">
                  <Row className="justify-content-center">
                    <Col
                      lg="4"
                      md="4"
                      sm="12"
                      xs="12"
                      className="text-end my-auto fttext"
                    >
                      <p className="mb-0 footertxt">
                        Download the app by clicking the link:
                      </p>
                    </Col>
                    <Col
                      lg="4"
                      md="4"
                      sm="12"
                      xs="12"
                      className="text-start fttext"
                    >
                      <img
                        src={imageplay}
                        className="footerimg me-3"
                        alt="Playstore"
                      />
                      <img
                        src={appstore}
                        className="footerimg"
                        alt="Appstore"
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg="12" className="justify-content-center">
                <p className="mt-2">
                  ©Genivis Remedies (I) Pvt. Ltd. All rights reserved.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}
