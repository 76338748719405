import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Slider from "react-slick";
import awareness1 from "../assets/images/awareness/awareness1.webp";
import awareness2 from "../assets/images/awareness/awareness2.webp";
import awareness3 from "../assets/images/awareness/awareness3.webp";
import awareness4 from "../assets/images/awareness/awareness4.webp";
import awareness5 from "../assets/images/awareness/awareness5.webp";
import awareness6 from "../assets/images/awareness/awareness6.webp";
import awareness7 from "../assets/images/awareness/awareness7.webp";
import youtube from "../assets/images/youtube.webp";
import awarenessvideo from "../assets/images/awarenessvideo.webp";
import awarenessvideo1 from "../assets/images/awarenessvideo1.webp";
import awarenessvideo2 from "../assets/images/awarenessvideo2.webp";
import awarenessvideo3 from "../assets/images/awarenessvideo3.webp";
import awarenessvideo4 from "../assets/images/awarenessvideo4.webp";
export default function GenericMedicineAwareness() {
  const [modalshow, setModalshow] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const handleClose = () => setModalshow(false);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };
  return (
    <div>
      <p className="subhead text-start">Generic Medicine Awareness</p>
      <Row className="justify-content-center sliderdivtraining mb-4">
        <Col lg="9">
          <p className="ffamily text-center pt-4">Spotlight</p>
          <Slider {...settings} className="">
            <Row>
              <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                <img
                  src={awareness1}
                  className="sliderimgmarketing"
                  alt="slide1"
                  loading="lazy"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                <img
                  src={awareness2}
                  className="sliderimgmarketing"
                  alt="slide2"
                  loading="lazy"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                <img
                  src={awareness3}
                  className="sliderimgmarketing"
                  alt="slide3"
                  loading="lazy"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                <img
                  src={awareness4}
                  className="sliderimgmarketing"
                  alt="slide4"
                  loading="lazy"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                <img
                  src={awareness5}
                  className="sliderimgmarketing"
                  alt="slide5"
                  loading="lazy"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                <img
                  src={awareness6}
                  className="sliderimgmarketing"
                  alt="slide6"
                  loading="lazy"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                <img
                  src={awareness7}
                  className="sliderimgmarketing"
                  alt="slide7"
                  loading="lazy"
                />
              </Col>
            </Row>
          </Slider>
        </Col>
      </Row>
      <div className="mb-5">
        <Row>
          <Col lg="6" md="6" sm="6" xs="6">
            <div
              className="viedostyle bgImageStyle"
              style={{
                backgroundImage: `url(${awarenessvideo})`,
              }}
            >
              <div>
                <img
                  src={youtube}
                  className="youtube"
                  alt="youtube"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalshow(true);
                    setVideoUrl("https://www.youtube.com/embed/U6VG3UvTSTQ");
                  }}
                />
              </div>
            </div>
          </Col>
          <Col lg="6" md="6" sm="6" xs="6">
            <div
              className="viedostyle bgImageStyle"
              style={{
                backgroundImage: `url(${awarenessvideo1})`,
              }}
            >
              <div>
                <img
                  src={youtube}
                  className="youtube"
                  alt="youtube"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalshow(true);
                    setVideoUrl("https://www.youtube.com/embed/XQ9u6nBOPdw");
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="6" md="6" sm="6" xs="6">
            <div
              className="viedostyle bgImageStyle"
              style={{
                backgroundImage: `url(${awarenessvideo2})`,
              }}
            >
              <div>
                <img
                  src={youtube}
                  className="youtube"
                  alt="youtube"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalshow(true);
                    setVideoUrl("https://www.youtube.com/embed/l4MJ1C-fXAU");
                  }}
                />
              </div>
            </div>
          </Col>
          <Col lg="6" md="6" sm="6" xs="6">
            <div
              className=" viedostyle bgImageStyle"
              style={{
                backgroundImage: `url(${awarenessvideo3})`,
              }}
            >
              <div>
                <img
                  src={youtube}
                  className="youtube"
                  alt="youtube"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalshow(true);
                    setVideoUrl("https://www.youtube.com/embed/uWbmIIsS4bs");
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4 justify-content-center">
          <Col lg="6" md="6" sm="6" xs="6">
            <div
              className="viedostyle bgImageStyle"
              style={{
                backgroundImage: `url(${awarenessvideo4})`,
              }}
            >
              <div>
                <img
                  src={youtube}
                  className="youtube"
                  alt="youtube"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalshow(true);
                    setVideoUrl("https://www.youtube.com/embed/r-wPYyXhyhE");
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        show={modalshow}
        size="lg"
        centered
        onHide={handleClose}
        style={{
          background: "transparent",
          border: "none",
        }}
        className="videoModal"
      >
        <Modal.Body className="p-0">
          <iframe
            width="100%"
            height="500"
            src={`${videoUrl}?autoplay=1`}
            title="YouTube video"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        </Modal.Body>
      </Modal>
    </div>
  );
}
