import React from "react";
import Layout from "./Layout";
import { CardBody, Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";

export default function ErrorEmail() {
  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);
  return (
    <Layout>
      <div className={`${isSafari === true ? "safari-style" : "home-style"}`}>
        <Row className="justify-content-center">
          <Col lg="4" md="4" sm="11" xs="11">
            <Card
              className="my-5 p-4"
              style={{ boxShadow: "#e6adad 10px 10px 5px" }}
            >
              <CardBody>
                <div className="text-center">
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    className="logoimg"
                    style={{ color: "red" }}
                  />
                </div>

                <p className="head">Email Failed</p>
                <p className="subtitlehead">
                  The form was Not Submitted!
                  <br />
                  Please Try again!
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}
