import React from 'react'
import { Row, Col } from "react-bootstrap";
import warhosevd from "../assets/images/warehouse/warhosevd.mp4";
export default function OurWarehouse() {
    return (
        <div>
            <p className='subhead text-start'>Our Warehouse</p>
            <Row>
                <Col lg="6" md="6" sm="6" xs="6">
                    <iframe
                        className="d-block w-100 viedostyle"
                        src={warhosevd}
                        type="video/mp4"
                        // title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    // allowFullScreen
                    ></iframe>
                </Col>
            </Row>
        </div>
    )
}
