import React, { useState } from "react";
import youtube from "../assets/images/youtube.webp";
import advertisementvideo from "../assets/images/advertisementvideo.webp";
import advertisementvideo2 from "../assets/images/advertisementvideo2.webp";
import advertisementvideo3 from "../assets/images/advertisementvideo3.webp";
import advertisementvideo4 from "../assets/images/advertisementvideo4.webp";
import { Row, Col, Modal } from "react-bootstrap";
export default function Advertisement() {
  const [modalshow, setModalshow] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const handleClose = () => setModalshow(false);
  return (
    <div>
      <div className="mb-5">
        <p className="subhead text-start">Advertisement Videos</p>
        <Row>
          <Col lg="6" md="6" sm="6" xs="6">
            <div
              className="viedostyle bgImageStyle"
              style={{
                backgroundImage: `url(${advertisementvideo})`,
              }}
            >
              <div>
                <img
                  src={youtube}
                  className="youtube"
                  alt="youtube"
                  loading="lazy"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalshow(true);
                    setVideoUrl("https://www.youtube.com/embed/E0MUS9YJSp0");
                  }}
                />
              </div>
            </div>
          </Col>
          <Col lg="6" md="6" sm="6" xs="6">
            <div
              className="viedostyle bgImageStyle"
              style={{
                backgroundImage: `url(${advertisementvideo3})`,
              }}
            >
              <div>
                <img
                  src={youtube}
                  className="youtube"
                  alt="youtube"
                  loading="lazy"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalshow(true);
                    setVideoUrl("https://www.youtube.com/embed/YQJaPsRHlBc");
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="6" md="6" sm="6" xs="6">
            <div
              className="viedostyle bgImageStyle"
              style={{
                backgroundImage: `url(${advertisementvideo2})`,
              }}
            >
              <div>
                <img
                  src={youtube}
                  className="youtube"
                  alt="youtube"
                  loading="lazy"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalshow(true);
                    setVideoUrl("https://www.youtube.com/embed/DJpRXAVNSQg");
                  }}
                />
              </div>
            </div>
          </Col>
          <Col lg="6" md="6" sm="6" xs="6">
            <div
              className="viedostyle bgImageStyle"
              style={{
                backgroundImage: `url(${advertisementvideo4})`,
              }}
            >
              <div>
                <img
                  src={youtube}
                  className="youtube"
                  alt="youtube"
                  loading="lazy"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalshow(true);
                    setVideoUrl("https://www.youtube.com/embed/zFEBjzCypPM");
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        show={modalshow}
        size="lg"
        centered
        onHide={handleClose}
        style={{
          background: "transparent",
          border: "none",
        }}
        className="videoModal"
      >
        <Modal.Body className="p-0">
          <iframe
            width="100%"
            height="500"
            src={`${videoUrl}?autoplay=1`}
            title="YouTube video"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        </Modal.Body>
      </Modal>
    </div>
  );
}
