import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
// import "./App.css";
import Home from "@/Pages/Home";
import Layout from "@/Pages/Layout";
import FranchiseInauguration from "./Pages/FranchiseInauguration";
import AboutUs from "./Pages/AboutUs";
import BiggestMilestone from "./Pages/BiggestMilestone";
import AdministrativeTeam from "./Pages/AdministrativeTeam";
import BusinessPartner from "./Pages/BusinessPartner";

import Contact from "./Pages/Contact";
import MyMapComponent from "./Pages/MyMapComponent";

import Jobs from "./Pages/Jobs";
import Job1 from "./Pages/Job1";
import Job2 from "./Pages/Job2";
import Franchise from "./Pages/Franchise";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import LandingBecomeBusinessPartner from "./Pages/LandingBecomeBusinessPartner";
import Thankyou from "./Pages/Thankyou";
import Careers from "./Pages/Careers";
import ErrorEmail from "./Pages/ErrorEmail";
// import { Helmet } from "react-helmet";
const paths = [
  "/gallery",
  "/franchiseInauguration",
  "/trainingProgram",
  "/awardRecognition",
  "/advertisementVideos",
  "/genericMedicineAwareness",
  "/franchiseModelDemo",
  "/ourWarehouse",
  "/pressRelease",
  "/marketingActivity",
];

function App() {
  return (
    <div className="App">
      {/* <Helmet>
        <title>Genivis Remedies</title>
        <meta name="description" content="Get info about Generic Medicines" />
        <meta name="keywords" content="medicine, Generic medicine" />
      </Helmet> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/layout" element={<Layout />} />
          {paths.map((path) => (
            <Route key={path} path={path} element={<FranchiseInauguration />} />
          ))}
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/biggestmilestone" element={<BiggestMilestone />} />
          <Route path="/administrativeteam" element={<AdministrativeTeam />} />
          <Route path="/businesspartner" element={<BusinessPartner />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/job1" element={<Job1 />} />
          <Route path="/job2" element={<Job2 />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/map" element={<MyMapComponent />} />
          <Route path="/franchise" element={<Franchise />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/errorEmail" element={<ErrorEmail />} />

          <Route
            path="/becomebusinessPartner"
            element={<LandingBecomeBusinessPartner />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
