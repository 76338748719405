import React from 'react'
import Layout from './Layout';
import { Row, Col } from "react-bootstrap";
export default function PrivacyPolicy() {
    return (
        <div>
            <Layout>
                <div className='about-style'>
                    <div className='about p-5'>
                        <Row className="justify-content-center">
                            <Col lg="9">
                                <p className='text-center mb-0'>
                                    Privacy Policy - Genivis Remedies
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <Row className="justify-content-center mt-4 px-2">
                        <Col lg="9">
                            <p className='text-style'>
                                Welcome to the official website of Genivis Remedies Pvt. Ltd. (“the Company”). We value
                                your privacy and are committed to protecting your personal information. This Privacy Policy
                                explains how we collect, use, and safeguard the data provided by visitors and users of our
                                website www.genivis.in (referred to as "the Website").
                            </p>
                            <p className='text-style'>
                                By accessing or using our Website, you agree to the practices described in this Privacy
                                Policy. If you do not agree, please do not use the Website.
                            </p>
                            <p className='subhead'> 1. Information We Collect</p>
                            <p className='text-style'>
                                We collect two types of information when you visit our Website:
                            </p>
                            <ol style={{ listStyle: "disc" }}>
                                <li className='adminhead'>
                                    Personal Information:
                                    <p className='text-style'>Information that personally identifies you, including:</p>
                                    <p className='text-style'>Name</p>
                                    <p className='text-style'>Email Address</p>
                                    <p className='text-style'>Contact details (phone number, address)</p>
                                    <p className='text-style'>Any information you voluntarily provide via forms or communication with us</p>
                                </li>
                                <li className='adminhead'>
                                    Non-Personal Information:
                                    <p className='text-style'>Information collected automatically,such as:</p>
                                    <p className='text-style'>IP address</p>
                                    <p className='text-style'>Browser type</p>
                                    <p className='text-style'>Pages visited and duration</p>
                                    <p className='text-style'>Cookies or tracking technologies used for improving your experience on our website</p>
                                </li>
                            </ol>
                            <p className='subhead'> 2. How We Use Your Information</p>
                            <p className='text-style'>
                                The information we collect is used for the following purposes:
                            </p>

                            <ol style={{ listStyle: "disc" }}>
                                <li>
                                    <p className='text-style'> To provide and improve our website’s services and user experience.</p>
                                </li>
                                <li>
                                    <p className='text-style'> To respond to your queries or requests submitted via the contact form.</p>
                                </li>
                                <li>
                                    <p className='text-style'>To send promotional emails, newsletters, or other updates (only if you’ve opted in).</p>
                                </li>
                                <li>
                                    <p className='text-style'>To ensure the security and integrity of our website.</p>

                                </li>
                            </ol>
                            <p className='subhead'> 3. Cookies and Tracking Technologies</p>
                            <p className='text-style'>
                                Our website uses cookies and other tracking technologies to enhance user experience, monitor Website traffic, and personalize content. You can manage cookie preferences through your browser settings.
                            </p>
                            <p className='subhead'> 4. Information Sharing</p>
                            <p className='text-style'>
                                We do not sell, trade, or otherwise transfer your personal information to outside parties, except:
                            </p>

                            <ol style={{ listStyle: "disc" }}>
                                <li>
                                    <p className='text-style'> To trusted third-party service providers (e.g., hosting services, payment processors) who assist us in operating the Website.</p>
                                </li>
                                <li>
                                    <p className='text-style'> If required by law, regulation, or legal process.</p>
                                </li>
                                <li>
                                    <p className='text-style'>To protect our rights, safety, or the rights of others.</p>
                                </li>

                            </ol>
                            <p className='subhead'> 5. Data Security</p>
                            <p className='text-style'>
                                We are committed to ensuring the security of your personal information. To safeguard your data, we employ a variety of security measures in compliance with relevant information security laws and regulations, including:
                            </p>
                            <ol style={{ listStyle: "disc" }}>
                                <li>
                                    <p className='text-style'>Information Technology Act, 2000 (India): We adhere to the provisions of the IT Act, ensuring reasonable security practices and procedures to protect sensitive personal information.</p>
                                </li>
                                <li>
                                    <p className='text-style'>Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011: These rules guide how we collect, use, store, and share sensitive personal data to ensure its safety.</p>
                                </li>
                                <li>
                                    <p className='text-style'>General Data Protection Regulation (GDPR - EU): For users within the European Economic Area (EEA), we comply with the GDPR, which ensures the protection of individuals' personal data and privacy.  </p>
                                </li>
                                <li>
                                    <p className='text-style'>While we implement robust measures to protect your data from unauthorized access, alteration, or disclosure, please be aware that no method of internet transmission or electronic storage is 100% secure.  </p>
                                </li>
                            </ol>

                            <p className='subhead'> 6. Data Retention</p>
                            <p className='text-style'>
                                We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.
                            </p>
                            <p className='subhead'> 7. Your Rights</p>
                            <p className='text-style'>
                                You have the right to:
                            </p>
                            <ol style={{ listStyle: "disc" }}>
                                <li>
                                    <p className='text-style'> Access, correct, or delete your personal information.</p>
                                </li>
                                <li>
                                    <p className='text-style'>Withdraw consent for processing your personal data.</p>
                                </li>
                                <li>
                                    <p className='text-style'>Opt-out of receiving marketing communications at any time by following the unsubscribe instructions in our emails.  </p>
                                </li>

                            </ol>
                            <p className='subhead'> 8. Third-Party Links</p>
                            <p className='text-style'>
                                Our Website may contain links to third-party websites. We are not responsible for the privacy practices or content of those websites. We encourage you to review their privacy policies when visiting third-party sites.
                            </p>
                            <p className='subhead'> 9. Changes to This Privacy Policy</p>
                            <p className='text-style'>
                                We may update this Privacy Policy from time to time. Changes will be posted on this page, and the date of the latest revision will be indicated at the top of the policy. Please review it periodically.
                            </p>
                            <p className='subhead'> 10. Contact Us</p>
                            <p className='text-style'>
                                If you have any questions or concerns regarding this Privacy Policy or your personal data, you can contact us at:
                            </p>
                            <p className='text-style'>
                                Email: prm@genivis.in
                            </p>
                            <p className='text-style'>
                                Website: www.genivis.in
                            </p>
                            <p className='text-style'>
                                By continuing to use the Website, you consent to this Privacy Policy and our compliance with the relevant information security acts.
                            </p>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </div>
    )
}
