import React from 'react'
import Layout from './Layout'
import downBig from "../assets/images/downArrowBig.png";
import milestone1st from "../assets/images/milestone1st.webp";
import franchise2024 from "../assets/images/franchise2024.webp";
import image8 from "../assets/images/image8.webp";
import trophy from "../assets/images/trophy.webp";
import vl from "../assets/images/vl.png";
import { Row, Col, Container } from "react-bootstrap";
export default function BiggestMilestone() {
  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);
  return (
    <Layout>
      <Container fluid>
        <div className={`${isSafari === true ? "aboutsafari" : "about-style"}`}>
          <div className="about p-5">
            <Row className="justify-content-center">
              <Col lg="9">
                <p className="text-center mb-0">Biggest Milestone</p>
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <Row className="justify-content-center">
              <Col lg="9">
                <Row className="justify-content-center">
                  <Col lg="8">
                    <p className="text-style text-center">
                      At Genivis, our commitment to advancing healthcare has
                      been marked by a series of significant milestones, each
                      representing a stride forward in our mission to improve
                      lives through innovative medicine.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row>
                <Col
                  lg={6}
                  md="6"
                  sm="12"
                  xs="12"
                  className="align-items-right my-auto centerstyle"
                >
                  <div className="d-flex justify-content-end align-items-center">
                    <img
                      src={milestone1st}
                      alt="milestone 1"
                      className="commonSizeImagesStyle my-auto"
                      loading="lazy"
                    />
                    <p className="newfont my-auto">@Genivis</p>
                    <div className="d-flex flex-column align-items-center">
                      <img
                        src={downBig}
                        alt=""
                        className="arrowStyle"
                        loading="lazy"
                      />
                      <div className="circle my-1"></div>
                      <img src={vl} alt="line" className="vl" loading="lazy" />
                      <div className="circle my-1"></div>
                    </div>
                  </div>
                </Col>

                <Col lg={6} md="6" sm="12" xs="12" className="my-auto">
                  <Row>
                    <Col lg="1" md="1" sm="1" xs="1"></Col>
                    <Col lg={10} md="11" sm="12" xs="12">
                      <p className="subhead">Opened 2000+ Franchises</p>
                      <p className="text-style">
                        Reducing every Indian citizen's healthcare budget by
                        providing quality generic medicines at attainable prices
                        by expanding our presence in multiple states of India
                        with more than 10,000 stores.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md="6" sm="12" xs="12" className="text-end">
                  <Row className="justify-content-end">
                    <Col lg={9} md="10">
                      <p className="subhead">Most Promising Retailer</p>
                      <p className="text-style">
                        Our company has been recognized by Silicon India
                        magazine as a Most Promising Retailer Franchise Company
                        for 2024 in pan India.
                      </p>
                    </Col>
                    <Col lg={2} md="2">
                      <div className="d-flex flex-column align-items-end">
                        <img
                          src={vl}
                          alt="line"
                          className="vl"
                          loading="lazy"
                        />
                        <div className="circle my-1"></div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md="6" sm="12" xs="12">
                  <div className="d-flex justify-content-start align-items-center">
                    <p className="newfont my-auto me-3">29th May 2024</p>
                    <img
                      src={franchise2024}
                      alt="milestone 2"
                      className="commonSizeImagesStyle"
                      loading="lazy"
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col
                  lg={6}
                  md="6"
                  sm="12"
                  xs="12"
                  className="align-items-right"
                >
                  <div className="d-flex justify-content-end align-items-center">
                    {/* <img src={oneimg} alt="" className='commonSizeImagesStyle my-auto' /> */}
                    <img
                      src={image8}
                      alt="milestone 3"
                      className="commonSizeImagesStyle my-auto"
                      loading="lazy"
                    />
                    <p className="newfont my-auto">29th May 2024</p>
                    <div className="d-flex flex-column align-items-center">
                      <img src={vl} alt="line" className="vl" loading="lazy" />
                      <div className="circle my-1"></div>
                    </div>
                  </div>
                </Col>

                <Col lg={6} md="6" sm="12" xs="12">
                  <Row>
                    <Col lg="1" md="1" sm="1" xs="1"></Col>
                    <Col lg={10} md="11" sm="12" xs="12">
                      <p className="subhead">Titled with India's No.1</p>
                      <p className="text-style">
                        Titled with India's No.1 Generic Medicines Franchise
                        Company for excellence in affordable healthcare by
                        Zee24Tas, Zee Media and Zee News on 29th May 2024.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md="6" sm="12" xs="12" className="text-end">
                  <Row className="justify-content-end">
                    <Col lg={9} md="10">
                      <p className="subhead">Top 10 Best Leader</p>
                      <p className="text-style">
                        Awarded as Top 10 Best Leaders in Pharma & Life sciences
                        2023 by CEO insight Magazine on 11 December 2023.
                      </p>
                    </Col>
                    <Col lg={2} md="2">
                      <div className="d-flex flex-column align-items-end">
                        <img
                          src={vl}
                          alt="line"
                          className="vl"
                          loading="lazy"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col lg={6} md="6" sm="12" xs="12">
                  <div className="d-flex justify-content-start align-items-center">
                    <p className="newfont my-auto me-3">11 Dec 2023</p>
                    <img
                      src={trophy}
                      alt="trophy"
                      className="commonSizeImagesStyle"
                      loading="lazy"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="my-1 newfont text-center">
                    Go Forward So Far
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
