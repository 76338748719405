import React from 'react'
import Layout from './Layout';
import { CardBody, Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

export default function Thankyou() {
    const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
    return (
      <Layout>
        <div className={`${isSafari === true ? "safari-style" : "home-style"}`}>
          <Row className="justify-content-center">
            <Col lg="4" md="4" sm="11" xs="11">
              <Card
                className="my-5 p-4"
                style={{ boxShadow: "10px 10px 5px lightblue" }}
              >
                <CardBody>
                  <div className="text-center">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="logoimg"
                      style={{ color: "green" }}
                    />
                  </div>

                  <p className="head">Thank You</p>
                  <p className="subtitlehead">
                    The form was Submitted Successfully
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
    );
}
