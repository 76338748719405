import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import youtube from "../assets/images/youtube.webp";
import framodeldemo from "../assets/images/framodeldemo.webp";
export default function FranchiseModelDemo() {
  const [modalshow, setModalshow] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const handleClose = () => setModalshow(false);
  return (
    <div>
      <p className="subhead text-start">Franchise Model Demo</p>

      <div className="mb-5">
        <Row>
          <Col lg="6" md="6" sm="6" xs="6">
            <div
              className="viedostyle bgImageStyle"
              style={{
                backgroundImage: `url(${framodeldemo})`,
              }}
            >
              <div>
                <img
                  src={youtube}
                  className="youtube"
                  alt="youtube"
                  loading="lazy"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalshow(true);
                    setVideoUrl("https://www.youtube.com/embed/3PvSpPxaVqM");
                  }}
                />
              </div>
            </div>
            {/* <iframe
                className="d-block w-100 viedostyle"
                src="https://www.youtube.com/embed/3PvSpPxaVqM"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                // allowFullScreen
              ></iframe> */}
          </Col>
        </Row>
      </div>
      <Modal
        show={modalshow}
        size="lg"
        centered
        onHide={handleClose}
        style={{
          background: "transparent",
          border: "none",
        }}
        className="videoModal"
      >
        <Modal.Body className="p-0">
          <iframe
            width="100%"
            height="500"
            src={`${videoUrl}?autoplay=1`}
            title="YouTube video"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        </Modal.Body>
      </Modal>
    </div>
  );
}
