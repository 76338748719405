import React, { useState } from 'react'
import { Row, Col, Modal } from "react-bootstrap";
// import Layout from './Layout';
import p4 from "../assets/images/pressr/p4.webp";
import p1 from "../assets/images/pressr/p1.webp";
import p2 from "../assets/images/pressr/p2.webp";
import p3 from "../assets/images/pressr/p3.webp";
import p5 from "../assets/images/pressr/p5.webp";
import p6 from "../assets/images/pressr/p6.webp";
import p7 from "../assets/images/pressr/p7.webp";
import press1 from "../assets/images/pressr/press1.webp";
import press22 from "../assets/images/pressr/press22.webp";
import press33 from "../assets/images/pressr/press33.webp";
import press44 from "../assets/images/pressr/press44.webp";
import press66 from "../assets/images/pressr/press66.webp";
export default function PressRelease() {
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const handleShow = (image) => {
        setSelectedImage(image);
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
        setSelectedImage('');
    };
    return (
      <div>
        <p className="subhead text-start">Press Release</p>
        <div className="mb-4">
          <Row className="justify-content-center">
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={p4}
                className="img-style"
                onClick={() => handleShow(p4)}
                alt="pressrelese4"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={press1}
                className="img-style"
                onClick={() => handleShow(press1)}
                alt="pressrelese1"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={p1}
                className="img-style"
                onClick={() => handleShow(p1)}
                alt="pressrelese2"
                loading="lazy"
              />
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={p2}
                className="img-style"
                onClick={() => handleShow(p2)}
                alt="pressrelese3"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={p3}
                className="img-style"
                onClick={() => handleShow(p3)}
                alt="pressrelese5"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={p5}
                className="img-style"
                onClick={() => handleShow(p5)}
                alt="pressrelese6"
                loading="lazy"
              />
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={p6}
                className="img-style"
                onClick={() => handleShow(p6)}
                alt="pressrelese7"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={p7}
                className="img-style"
                onClick={() => handleShow(p7)}
                alt="pressrelese8"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={press22}
                className="img-style"
                onClick={() => handleShow(press22)}
                alt="pressrelese9"
                loading="lazy"
              />
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={press33}
                className="img-style"
                onClick={() => handleShow(press33)}
                alt="pressrelese10"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={press44}
                className="img-style"
                onClick={() => handleShow(press44)}
                alt="pressrelese11"
                loading="lazy"
              />
            </Col>
            <Col lg="4" md="4" sm="6" xs="6">
              <img
                src={press66}
                className="img-style"
                onClick={() => handleShow(press66)}
                alt="pressrelese12"
                loading="lazy"
              />
            </Col>
          </Row>
          <Modal show={show} onHide={handleClose} centered className="modal-lg">
            <Modal.Body className="text-center">
              <img src={selectedImage} alt="Selected" className="img-fluid" />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
}
