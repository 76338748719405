import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Slider from "react-slick";
import awardvideo from "../assets/images/awardvideo.mp4";
import bigfm from "../assets/images/bigfm.mp4";
import award1 from "../assets/images/awardsnew/award1.jpg";
import award2 from "../assets/images/awardsnew/award2.jpg";
import award3 from "../assets/images/awardsnew/award3.webp";
import award4 from "../assets/images/awardsnew/award4.jpg";
import youtube from "../assets/images/youtube.webp";
import forthyoutube from "../assets/images/forthyoutube.webp";
export default function AwardsRecognition() {
  const [modalshow, setModalshow] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const handleClose = () => setModalshow(false);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };
  return (
    <div>
      <p className="subhead text-start">Award & Recognition's</p>
      <Row className="justify-content-center sliderdivtraining mb-4">
        <Col lg="9">
          <p className="ffamily text-center pt-4">Spotlight</p>
          <Slider {...settings} className="">
            <Row>
              <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                <img
                  src={award1}
                  className="sliderimgmarketing"
                  alt="slide1"
                  loading="lazy"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                <img
                  src={award2}
                  className="sliderimgmarketing"
                  alt="slide2"
                  loading="lazy"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                <img
                  src={award3}
                  className="sliderimgmarketing"
                  alt="slide3"
                  loading="lazy"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                <img
                  src={award4}
                  className="sliderimgmarketing"
                  alt="slide4"
                  loading="lazy"
                />
              </Col>
            </Row>
          </Slider>
        </Col>
      </Row>
      <div className="mb-4">
        <Row className="justify-content-center mb-4">
          <Col lg="6" md="6" sm="6" xs="6">
            <div
              className="viedostyle bgImageStyle"
              style={{
                backgroundImage: `url(${forthyoutube})`,
              }}
            >
              <div>
                <img
                  src={youtube}
                  className="youtube"
                  alt="youtube"
                  loading="lazy"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalshow(true);
                    setVideoUrl("https://www.youtube.com/embed/TMNJc4SQTnQ");
                  }}
                />
              </div>
            </div>
          </Col>
          <Col lg="6" md="6" sm="6" xs="6">
            <video
              className="d-block w-100 viedostyle"
              src={awardvideo}
              controls
              autoPlay={false} // Ensure autoplay is false
            ></video>
          </Col>
        </Row>
        <Row>
          <Col lg="6" md="6" sm="6" xs="6">
            <video
              className="d-block w-100 viedostyle"
              src={bigfm}
              controls
              autoPlay={false} // Ensure autoplay is false
            ></video>
          </Col>
        </Row>
      </div>
      <Modal
        show={modalshow}
        size="lg"
        centered
        onHide={handleClose}
        style={{
          background: "transparent",
          border: "none",
        }}
        className="videoModal"
      >
        <Modal.Body className="p-0">
          <iframe
            width="100%"
            height="500"
            src={`${videoUrl}?autoplay=1`}
            title="YouTube video"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        </Modal.Body>
      </Modal>
    </div>
  );
}
