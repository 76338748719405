import React, { useState } from 'react'
import { Row, Col, Modal } from "react-bootstrap";
import Slider from "react-slick";
import training5 from "../assets/images/trainingnew/training5.webp";
import training6 from "../assets/images/trainingnew/training6.webp";
import training20 from "../assets/images/trainingnew/training20.jpg";
import training7 from "../assets/images/trainingnew/training7.webp";
import training8 from "../assets/images/trainingnew/training8.webp";
import training1 from "../assets/images/trainingnew/training1.webp";
import training2 from "../assets/images/trainingnew/training2.webp";
import training21 from "../assets/images/trainingnew/training21.webp";
import training22 from "../assets/images/trainingnew/training22.webp";
import train1 from "../assets/images/trainingnew/train1.webp";
import train2 from "../assets/images/trainingnew/train2.webp";
import training50 from "../assets/images/trainingnew/training50.jpg";
import training19 from "../assets/images/trainingnew/training19.jpg";
import training3 from "../assets/images/trainingnew/training3.webp";
import training18 from "../assets/images/trainingnew/training18.jpg";
import training16 from "../assets/images/trainingnew/training16.webp";
import training17 from "../assets/images/trainingnew/training17.jpg";
import training11 from "../assets/images/trainingnew/training11.jpg";
import training12 from "../assets/images/trainingnew/training12.webp";
import training13 from "../assets/images/trainingnew/training13.jpg";
import training14 from "../assets/images/trainingnew/training14.jpg";
import training15 from "../assets/images/trainingnew/training15.webp";
import training9 from "../assets/images/trainingnew/training9.webp";
import training4 from "../assets/images/trainingnew/training4.jpg";
import training10 from "../assets/images/trainingnew/training10.jpg";
export default function TrainingProgram() {
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const handleShow = (image) => {
        setSelectedImage(image);
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
        setSelectedImage('');
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    autoplay: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    autoplay: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                }
            }
        ]
    };
    return (
      <div>
        <>
          <p className="subhead text-start">Training Program</p>
          <Row className="justify-content-center sliderdivtraining mb-4">
            <Col lg="9">
              <p className="ffamily text-center pt-4">Spotlight</p>
              <Slider {...settings} className="">
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={training19}
                      className="sliderimgtraining"
                      alt="trainingimg1"
                      loading="lazy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={training3}
                      className="sliderimgtraining"
                      alt="trainingimg2"
                      loading="lazy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={training18}
                      className="sliderimgtraining"
                      alt="trainingimg3"
                      loading="lazy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={training16}
                      className="sliderimgtraining"
                      alt="trainingimg4"
                      loading="lazy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={training17}
                      className="sliderimgtraining"
                      alt="trainingimg5"
                      loading="lazy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={training11}
                      className="sliderimgtraining"
                      alt="trainingimg6"
                      loading="lazy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={training12}
                      className="sliderimgtraining"
                      alt="trainingimg7"
                      loading="lazy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={training13}
                      className="sliderimgtraining"
                      alt="trainingimg8"
                      loading="lazy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={training14}
                      className="sliderimgtraining"
                      alt="trainingimg9"
                      loading="lazy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={training15}
                      className="sliderimgtraining"
                      alt="trainingimg10"
                      loading="lazy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={training10}
                      className="sliderimgtraining"
                      alt="trainingimg11"
                      loading="lazy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={training9}
                      className="sliderimgtraining"
                      alt="trainingimg12"
                      loading="lazy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={training4}
                      className="sliderimgtraining"
                      alt="trainingimg13"
                      loading="lazy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={train1}
                      className="sliderimgtraining"
                      alt="trainingimg14"
                      loading="lazy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={train2}
                      className="sliderimgtraining"
                      alt="trainingimg15"
                      loading="lazy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                    <img
                      src={training50}
                      className="sliderimgtraining"
                      alt="trainingimg16"
                      loading="lazy"
                    />
                  </Col>
                </Row>
              </Slider>
            </Col>
          </Row>
          <div className="mb-4">
            <Row className="justify-content-center">
              <Col lg="4" md="4" sm="6" xs="6">
                <img
                  src={training22}
                  className="img-style"
                  onClick={() => handleShow(training22)}
                  alt="trainingimg17"
                  loading="lazy"
                />
              </Col>
              <Col lg="4" md="4" sm="6" xs="6">
                <img
                  src={training21}
                  className="img-style"
                  onClick={() => handleShow(training21)}
                  alt="trainingimg18"
                  loading="lazy"
                />
              </Col>
              <Col lg="4" md="4" sm="6" xs="6">
                <img
                  src={training2}
                  className="img-style"
                  onClick={() => handleShow(training2)}
                  alt="trainingimg19"
                  loading="lazy"
                />
              </Col>
            </Row>
            <Row className="mt-4 justify-content-center">
              <Col lg="4" md="4" sm="6" xs="6">
                <img
                  src={training1}
                  className="img-style"
                  onClick={() => handleShow(training1)}
                  alt="trainingimg20"
                  loading="lazy"
                />
              </Col>
              <Col lg="4" md="4" sm="6" xs="6">
                <img
                  src={training8}
                  className="img-style"
                  onClick={() => handleShow(training8)}
                  alt="trainingimg21"
                  loading="lazy"
                />
              </Col>
              <Col lg="4" md="4" sm="6" xs="6">
                <img
                  src={training7}
                  className="img-style"
                  onClick={() => handleShow(training7)}
                  alt="trainingimg22"
                  loading="lazy"
                />
              </Col>
            </Row>
            <Row className="mt-4 justify-content-center">
              <Col lg="4" md="4" sm="6" xs="6">
                <img
                  src={training20}
                  className="img-style"
                  onClick={() => handleShow(training20)}
                  alt="trainingimg23"
                  loading="lazy"
                />
              </Col>
              <Col lg="4" md="4" sm="6" xs="6">
                <img
                  src={training6}
                  className="img-style"
                  onClick={() => handleShow(training6)}
                  alt="trainingimg24"
                  loading="lazy"
                />
              </Col>
              <Col lg="4" md="4" sm="6" xs="6">
                <img
                  src={training5}
                  className="img-style"
                  onClick={() => handleShow(training5)}
                  alt="trainingimg25"
                  loading="lazy"
                />
              </Col>
            </Row>
            <Modal
              show={show}
              onHide={handleClose}
              centered
              className="modal-lg"
            >
              <Modal.Body className="text-center">
                <img src={selectedImage} alt="Selected" className="img-fluid" />
              </Modal.Body>
            </Modal>
          </div>
        </>
      </div>
    );
}
