// Product Level DropDown style
export let statedropdown = "";
if (window.matchMedia("(min-width:700px) and (max-width:1024px)").matches) {
  statedropdown = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#FFFFFF",
      border: state.isFocused
        ? "1px solid #00a0f5 !important"
        : "1px solid #DEE2E6",
      boxShadow: "none",
      height: "25px",
      minHeight: "25px",
      borderRadius: "4px",
      fontFamily: "Be Vietnam Pro !important",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "10px",
      color: "#383838",

      "&:hover": {
        border: "1px solid #DEE2E6",
      },
      background: state.isFocused ? "#ffffff !important" : "",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#383838",
      padding: "5px",
      "&:hover": {
        color: "#383838",
      },
    }),
    menu: (base) => ({ ...base, zIndex: 9999, fontSize: "10px" }),
  };
} else if (
  window.matchMedia("(min-width:1025px) and (max-width:1280px)").matches
) {
  statedropdown = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#FFFFFF",
      border: state.isFocused
        ? "1px solid #2684FF !important"
        : "1px solid #DEE2E6",
      boxShadow: "none",
      height: "28px",
      minHeight: "28px",
      borderRadius: "3px",
      fontFamily: "Be Vietnam Pro !important",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
      padding: "0px 0px !important",

      "&:hover": {
        border: "1px solid #DEE2E6",
      },
      background: state.isFocused ? "#ffffff !important" : "",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#383838",
      padding: "5px",
      "&:hover": {
        color: "#383838",
      },
    }),
    menu: (base) => ({
      ...base,
      zIndex: 999,
      fontSize: "10px",
    }),
  };
} else if (
  window.matchMedia("(min-width:1281px) and (max-width:1368px)").matches
) {
  statedropdown = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#FFFFFF",
      border: state.isFocused
        ? "1px solid #2684FF !important"
        : "1px solid #DEE2E6",
      boxShadow: "none",
      height: "28px",
      minHeight: "28px",
      borderRadius: "4px",
      fontFamily: "Be Vietnam Pro !important",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "17px",
      color: "#000000",
      "&:hover": {
        border: "1px solid #DEE2E6",
      },
      background: state.isFocused ? "#ffffff !important" : "",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#383838",
      padding: "5px",
      "&:hover": {
        color: "#383838",
      },
    }),
    menu: (base) => ({
      ...base,
      zIndex: 999,
      fontSize: "12px",
    }),
  };
} else if (
  window.matchMedia("(min-width:1369px) and (max-width:1440px)").matches
) {
  statedropdown = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#FFFFFF",
      border: state.isFocused
        ? "1px solid #2684FF !important"
        : "1px solid #DEE2E6",
      boxShadow: "none",
      height: "30px",
      minHeight: "30px",
      borderRadius: "3px",
      fontFamily: "Be Vietnam Pro !important",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "17px",
      color: "#000000",
      padding: "2px 2px",
      "&:hover": {
        border: "1px solid #DEE2E6",
      },
      background: state.isFocused ? "#ffffff !important" : "",
    }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#383838",
      padding: "5px",
      "&:hover": {
        color: "#383838",
      },
    }),
    menu: (base) => ({
      ...base,
      zIndex: 999,
      fontSize: "12px",
    }),
  };
} else if (
  window.matchMedia("(min-width:1441px) and (max-width:1600px)").matches
) {
  statedropdown = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#FFFFFF",
      border: state.isFocused
        ? "1px solid #2684FF !important"
        : "1px solid #DEE2E6",
      boxShadow: "none",
      height: "30px",
      minHeight: "30px",
      borderRadius: "3px",
      fontFamily: "Be Vietnam Pro !important",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "17px",
      color: "#000000",
      padding: "2px 2px",
      "&:hover": {
        border: "1px solid #DEE2E6",
      },
      background: state.isFocused ? "#ffffff !important" : "",
    }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#383838",
      padding: "5px",
      "&:hover": {
        color: "#383838",
      },
    }),
    menu: (base) => ({
      ...base,
      zIndex: 999,
      fontSize: "12px",
    }),
  };
} else if (
  window.matchMedia("(min-width:1601px) and (max-width:1680px)").matches
) {
  statedropdown = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#FFFFFF",
      border: state.isFocused
        ? "1px solid #2684FF !important"
        : "1px solid #DEE2E6",
      boxShadow: "none",
      height: "32px",
      minHeight: "32px",
      borderRadius: "3px",
      fontFamily: "Be Vietnam Pro !important",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "17px",
      color: "#000000",
      padding: "2px 2px",
      "&:hover": {
        border: "1px solid #DEE2E6",
      },
      background: state.isFocused ? "#ffffff !important" : "",
    }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#383838",
      padding: "5px",
      "&:hover": {
        color: "#383838",
      },
    }),
    menu: (base) => ({
      ...base,
      zIndex: 999,
      fontSize: "12px",
    }),
  };
} else {
  statedropdown = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (base, state) => ({
      ...base,
      backgroundColor: "#FFFFFF",
      border: "1px solid #DEE2E6",
      boxShadow: "none",
      height: "34px",
      minHeight: "34px",
      borderRadius: "3px",
      fontFamily: "Be Vietnam Pro !important",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "17px",
      color: "#000000",
      "&:hover": {
        border: "1px solid #DEE2E6",
      },
      "&:focus": {
        background: "transparent !important",
        border: "1px solid #DEE2E6",
      },
      "&:active": {
        background: "transparent",
        border: "1px solid #DEE2E6",
      },
      padding: state.isFocused ? "0px !important" : "0px",
      background: state.isFocused ? "transparent !important" : "",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#000",
      padding: "5px",
      "&:hover": {
        color: "#000",
      },
    }),
    closingIndicator: (base) => ({
      ...base,
      color: "#383838",
      padding: "5px",
      "&:hover": {
        color: "#383838",
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "transparent",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 999,
      fontSize: "14px",
    }),
    control: (base, state) => ({
      ...base,
      borderRadius: "0.375rem",
    }),
  };
}
