import React, { useState } from 'react'
import Layout from './Layout'

import asif from "../assets/images/business/asif.webp";
import shailendra from "../assets/images/business/shailendra.webp";
import avtar1 from "../assets/images/business/avtar1.webp";
import avtar3 from "../assets/images/business/avtar3.webp";
import avtar4 from "../assets/images/business/avtar4.webp";
import shivkumarsansudi from "../assets/images/business/shivkumarsansudi.webp";
import vishwnathhiremat from "../assets/images/business/vishwnathhiremat.webp";
import anand from "../assets/images/business/anand.webp";
import mohan from "../assets/images/business/mohan.webp";
import sumit from "../assets/images/business/sumit.webp";
import pravin from "../assets/images/business/pravin.webp";
import nasirkhan from "../assets/images/business/nasirkhan.webp";
import kushal from "../assets/images/business/kushal.webp";
import ravindra from "../assets/images/business/ravindra.webp";
import sourabh from "../assets/images/business/sourabh.webp";
import ajit from "../assets/images/business/ajit.webp";
import ashish from "../assets/images/business/ashish.webp";
import tamboli from "../assets/images/business/tamboli.webp";
import tusharshinde from "../assets/images/business/tusharshinde.webp";
import mekatiwar from "../assets/images/business/mekatiwar.webp";
import vinay from "../assets/images/business/vinay.webp";
import manoj from "../assets/images/business/manoj.webp";
import aswinik from "../assets/images/business/aswinik.webp";
import aadesh from "../assets/images/business/aadesh.webp";
import kumbhkaran from "../assets/images/business/kumbhkaran.webp";
import maruti from "../assets/images/business/maruti.webp";
import chetansapariya from "../assets/images/business/chetansapariya.webp";
import sapaldesaimin from "../assets/images/business/sapaldesaimin.webp";
import mayursapariya from "../assets/images/business/mayursapariya.webp";
import prashantpatil from "../assets/images/business/prashantpatil.webp";
import Select from "react-select";
import { statedropdown } from "../helpers";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
export default function BusinessPartner() {
  const isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);
  const defaultOption = { value: "maharashtra", label: "Maharashtra" };
  const [selectedTeam, setSelectedTeam] = useState(defaultOption);

  const handleTeamChange = (selectedOption) => {
    setSelectedTeam(selectedOption);
  };

  const [selectedTab, setSelectedTab] = useState("State Head");
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const teamOptions = [
    { value: "maharashtra", label: "Maharashtra" },
    { value: "gujrat", label: "Gujrat" },
    { value: "odisha", label: "Odisha" },
    { value: "karnataka", label: "Karnataka" },
  ];

  const teamMembers = {
    maharashtra: [
      {
        image: shailendra,
        name: "Shailendra Pawar",
        position: "Maharashtra",
        category: "State Head",
      },
      {
        image: asif,
        name: "Asif Chiniwar",
        position: "Maharashtra",
        category: "State Head",
      },
      {
        image: anand,
        name: "Anand Basutkar",
        position: "PCMC,Pune",
        category: "District Head",
      },
      {
        image: mohan,
        name: "Mohan Wagle",
        position: "Ratnagiri/Sindhudurg",
        category: "District Head",
      },
      {
        image: sumit,
        name: "Sumit Kolpak",
        position: "Latur",
        category: "District Head",
      },
      {
        image: pravin,
        name: "Pravin Shedge",
        position: "Satara",
        category: "District Head",
      },
      {
        image: nasirkhan,
        name: "Nasirkhan Pathan",
        position: "Nashik",
        category: "District Head",
      },
      {
        image: kushal,
        name: "Kushal Kukade",
        position: "Kolhapur",
        category: "District Head",
      },
      {
        image: ravindra,
        name: "Ravindra Sonvane",
        position: "Beed",
        category: "District Head",
      },
      {
        image: sourabh,
        name: "Sourabh Ghodinde",
        position: "Raigad/New Mumbai",
        category: "District Head",
      },
      {
        image: ajit,
        name: "Ajit Mahadik",
        position: "Pune",
        category: "District Head",
      },
      {
        image: shailendra,
        name: "Shailendra Pawar",
        position: "Pune(City)",
        category: "District Head",
      },
      {
        image: tusharshinde,
        name: "Tushar Shinde",
        position: "Pune",
        category: "District Head",
      },
      {
        image: ashish,
        name: "Ashish Chandurkar",
        position: "Nagpur",
        category: "District Head",
      },
      {
        image: tamboli,
        name: "Asif Tamboli",
        position: "Pune Rural & City",
        category: "District Head",
      },
      {
        image: asif,
        name: "Asif Chiniwar",
        position: "Solapur",
        category: "District Head",
      },
      {
        image: avtar4,
        name: "Vineet Wakade",
        position: "Raigad/New Mumbai",
        category: "District Head",
      },
      {
        image: vinay,
        name: "Vinay Kale",
        position: "PCMC,Pune",
        category: "District Head",
      },
      {
        image: manoj,
        name: "Manoj Patil",
        position: "Nashik",
        category: "District Head",
      },
      {
        image: aadesh,
        name: "Aadesh Bhujbal",
        position: "PCMC,Pune",
        category: "District Head",
      },
      {
        image: kumbhkaran,
        name: "Pravin Kumbhkaran",
        position: "Parbhani",
        category: "District Head",
      },
      {
        image: maruti,
        name: "Maruti Bhalerao",
        position: "Washim, Hingoli",
        category: "District Head",
      },
      {
        image: avtar4,
        name: "Adwait Pathare",
        position: "Ahmednagar",
        category: "District Head",
      },
      // { image: avtar1, name: 'Jalpa Nimish Shah', position: 'Mumbai/Thane', category: 'District Head' },
      // { image: avtar2, name: 'Jaishri Arvind Maurya', position: 'Mumbai/Thane', category: 'District Head' },
      // { image: neha, name: 'Neha Nikhilkumar Oza', position: 'Mumbai/Thane', category: 'District Head' },
      {
        image: avtar3,
        name: "Amit Bobade",
        position: "Kalyan,Dombiwali, Badlapur, Ambarnath, Ulhasnagar",
        category: "District Head",
      },
      {
        image: mekatiwar,
        name: "Pravin Mekartiwar",
        position: " Chandrapur Gadchiroli",
        category: "District Head",
      },
      {
        image: pravin,
        name: "Pravin Shedge",
        position: "Satara/Sangali/Kolhapur",
        category: "Regional Head",
      },
      {
        image: prashantpatil,
        name: "Prashant Patil",
        position: "Jalgaon/Dhule/Nandurbar",
        category: "Regional Head",
      },
      {
        image: shailendra,
        name: "Shailendra Pawar",
        position: "Pune",
        category: "Regional Head",
      },
      {
        image: ashish,
        name: "Ashish Chandurkar",
        position: "Nagpur/Wardha/Bhandara/Gondia",
        category: "Regional Head",
      },
      {
        image: asif,
        name: "Asif Chiniwar",
        position: "Pune/Solapur/Osmanabad/Latur",
        category: "Regional Head",
      },
      {
        image: ajit,
        name: "Ajit Mahadik",
        position: "Nashik/Aurangabad/Ahmednagar",
        category: "Regional Head",
      },
      {
        image: avtar3,
        name: "Sanjay Joshi",
        position: "Mumbai/Thane",
        category: "Regional Head",
      },
      // { image: avtar2, name: 'Jaishri Arvind Maurya', position: 'Mumbai/Thane', category: 'Regional Head' },
      // { image: neha, name: 'Neha Nikhilkumar Oza', position: 'Mumbai/Thane', category: 'Regional Head' },
      // add other members
    ],
    odisha: [
      {
        image: aswinik,
        name: "Aswinikumar Khadanga",
        position: "Odisha",
        category: "State Head",
      },
      // { image: 'asif.png', name: 'Member 4', position: 'Position 4', category: 'Regional Head' },
      // add other members
    ],
    gujrat: [
      // { image: avtar1, name: 'Jalpa Nimish Shah', position: 'Mumbai/Thane', category: 'State Head' },
      // { image: avtar2, name: 'Jaishri Arvind Maurya', position: 'Mumbai/Thane', category: 'State Head' },
      // { image: neha, name: 'Neha Nikhilkumar Oza', position: 'Mumbai/Thane', category: 'State Head' },

      {
        image: avtar3,
        name: "Amit Bobade",
        position: "Bharuch-Narmada-Tapi-Navsari-Valsad-Dang(South Gujarat) ",
        category: "Regional Head",
      },
      {
        image: avtar1,
        name: "Minakshi Bobade",
        position: "Bharuch-Narmada-Tapi-Navsari-Valsad-Dang(South Gujarat) ",
        category: "Regional Head",
      },
      {
        image: avtar3,
        name: "Mohan Khaire",
        position: "Bharuch-Narmada-Tapi-Navsari-Valsad-Dang(South Gujarat) ",
        category: "Regional Head",
      },
      {
        image: sapaldesaimin,
        name: "Sapal Desai",
        position: "Baroda-Anand-Kheda",
        category: "Regional Head",
      },
      {
        image: sapaldesaimin,
        name: "Sapal Desai",
        position: "Baroda-Anand-Kheda",
        category: "District Head",
      },
      {
        image: chetansapariya,
        name: "Chetan Saparia",
        position: "Baroda",
        category: "District Head",
      },
      {
        image: mayursapariya,
        name: "Mayur Sapariya",
        position: "Baroda",
        category: "District Head",
      },
      // add other members
    ],
    karnataka: [
      {
        image: shivkumarsansudi,
        name: "Shivkumar Sansudi",
        position: "Karnataka",
        category: "State Head",
      },
      {
        image: shivkumarsansudi,
        name: "Shivkumar Sansudi",
        position: "Belgaum",
        category: "District Head",
      },
      {
        image: vishwnathhiremat,
        name: "Vishwanath Hiremath",
        position: "Bijapur",
        category: "District Head",
      },
      {
        image: shivkumarsansudi,
        name: "Shivkumar Sansudi",
        position: "Belgaum/Dharwad/Bagalkot/Vijaypur",
        category: "Regional Head",
      },

      // add other members
    ],
  };

  return (
    <Layout>
      <Container fluid>
        <div className={`${isSafari === true ? "aboutsafari" : "about-style"}`}>
          <div className="about p-5">
            <Row className="justify-content-center">
              <Col lg="9">
                <p className="text-center mb-0">Business Partners</p>
              </Col>
            </Row>
          </div>

          <div className="pt-4" style={{ backgroundColor: "#f9f9f9" }}>
            <Row className="justify-content-center">
              <Col lg="9">
                <Row className="justify-content-center">
                  <Col lg="8" className="text-center">
                    <Row className="justify-content-center">
                      <Col
                        lg="8"
                        className=""
                        style={{ borderRight: "1px solid #d9d9d9" }}
                      >
                        <Button
                          className={`btnstyle me-2 ${
                            selectedTab === "State Head"
                              ? "btnstyle-active"
                              : ""
                          }`}
                          onClick={() => handleTabChange("State Head")}
                        >
                          State Head
                        </Button>
                        <Button
                          className={`btnstyle me-2 ${
                            selectedTab === "Zonal Head"
                              ? "btnstyle-active"
                              : ""
                          }`}
                          onClick={() => handleTabChange("Zonal Head")}
                        >
                          Zonal Head
                        </Button>
                        <Button
                          className={`btnstyle me-2 ${
                            selectedTab === "Regional Head"
                              ? "btnstyle-active"
                              : ""
                          }`}
                          onClick={() => handleTabChange("Regional Head")}
                        >
                          Regional Head
                        </Button>
                        <Button
                          className={`btnstyle me-2 ${
                            selectedTab === "District Head"
                              ? "btnstyle-active"
                              : ""
                          }`}
                          onClick={() => handleTabChange("District Head")}
                        >
                          District Head
                        </Button>
                      </Col>
                      <Col lg="3">
                        <Form.Group>
                          <Select
                            className="selectTo"
                            isClearable={true}
                            styles={statedropdown}
                            placeholder="Maharashtra"
                            name="team"
                            id="team"
                            options={teamOptions}
                            onChange={handleTeamChange}
                            defaultValue={defaultOption}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {selectedTeam && (
                  <Row className="mt-4 justify-content-center">
                    {teamMembers[selectedTeam.value]
                      .filter((member) => member.category === selectedTab)
                      .map((member, index) => (
                        <Col
                          key={index}
                          lg="3"
                          md="3"
                          sm="6"
                          xs="6"
                          justify-content-center
                        >
                          <Card
                            style={{
                              border: "transparent",
                              backgroundColor: "transparent",
                            }}
                          >
                            <Card.Img
                              variant="top"
                              src={member.image}
                              className="admin-img"
                              alt="img"
                            />
                            <Card.Body>
                              <Card.Title className="adminhead">
                                {member.name}
                              </Card.Title>
                              <Card.Text className="admintxt">
                                {member.position}
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
